import { ObjectOf } from "../../common/types/ObjectOf";

export const LiquidPassAssistancesJSON: ObjectOf<string> = {
  0: "Informação não encontrada.",
  1: "Pensão por morte do trabalhador rural",
  10: "Auxílio doença por acidente do trabalho do trabalhador rural",
  11: "Renda mensal vitalícia por invalidez do trabalhador rural",
  12: "Renda mensal vitalícia por idade do trabalhador rural",
  13: "Auxílio doença do trabalhador rural",
  15: "Auxílio reclusão do trabalhador rural",
  2: "Pensão por morte por acidente do trabalho do trabalhador rural",
  200: "Bolsa família",
  201: "Garantia safra",
  202: "Seguro defeso",
  203: "PETI",
  204: "Auxílio emergencial",
  205: "Auxílio Brasil",
  206: "Seguro desemprego",
  21: "Pensão por morte",
  22: "Pensão por morte estatutária",
  23: "Pensão por morte de ex-combatente",
  24: "Pensão especial - ato institucional",
  25: "Auxílio reclusão",
  26: "Pensão especial",
  27: "Pensão por morte de servidor público federal com dupla aposentadoria",
  28: "Pensão por morte do regime geral",
  29: "Pensão por morte de ex-combatente marítimo",
  3: "Pensão por morte do empregador rural",
  30: "Renda mensal vitalícia por invalidez",
  31: "Auxílio doença",
  32: "Aposentadoria por invalidez",
  33: "Aposentadoria por invalidez de aeronauta",
  34: "Aposentadoria por invalidez de ex-combatente marítimo",
  36: "Benefício decorrente de acidente de qualquer natureza ou causa",
  37: "Aposentadoria de extranumerário da União",
  38: "Aposentadoria da extinta CAPIN",
  4: "Aposentadoria por invalidez do trabalhador rural",
  40: "Renda mensal vitalícia por idade",
  41: "Aposentadoria por idade",
  42: "Aposentadoria por tempo de contribuição",
  43: "Aposentadoria de ex-combatente",
  44: "Aposentadoria por tempo de contribuição de aeronauta",
  45: "Aposentadoria por tempo de contribuição de jornalista profissional",
  46: "Aposentadoria especial",
  47: "Abono de permanência em serviço 25%",
  48: "Abono de permanência em serviço 20%",
  49: "Aposentadoria por tempo de contribuição ordinária",
  5: "Aposentadoria por invalidez por acidente do trabalho do trabalhador rural",
  50: "Auxílio doença - Extinto Plano Básico",
  51: "Aposentadoria por invalidez - Extinto Plano Básico",
  52: "Aposentadoria por idade - Extinto Plano Básico",
  54: "Pensão especial vitalícia",
  55: "Pensão por morte - Extinto Plano Básico",
  56: "Pensão especial as vítimas da talidomida",
  57: "Aposentadoria por tempo de contribuição de professor",
  58: "Aposentadoria excepcional do anistiado",
  59: "Pensão por morte excepcional do anistiado",
  6: "Aposentadoria por invalidez do empregador rural",
  60: "Pensão especial mensal vitalícia",
  68: "Pecúlio especial de aposentadoria",
  7: "Aposentadoria por idade do trabalhador rural",
  72: "Aposentadoria por tempo de contribuição de ex-combatente marítimo",
  76: "Salário família estatutário da RFFSA",
  78: "Aposentadoria por idade de ex-combatente marítimo",
  79: "Abono de servidor aposentado pela autarquia empr",
  8: "Aposentadoria por idade do empregador rural",
  80: "Salário maternidade",
  81: "Aposentadoria por idade compulsória - ex-SASSE",
  82: "Aposentadoria por tempo de contribuição - ex-SASSE",
  83: "Aposentadoria por invalidez - ex-SASSE",
  84: "Pensão por morte - ex-SASSE",
  85: "Pensão mensal vitalícia do seringueiro",
  86: "Pensão mensal vitalícia do dep. do seringueiro",
  87: "Amparo social a pessoa portadora de deficiência",
  88: "Amparo social ao idoso",
  89: "Pensão especial aos dependentes de vítimas fatais por contaminação na hemodiálise",
  9: "Complemento por acidente trabalho por trabalhador - rural",
  91: "Auxílio doença - acidente do trabalho",
  92: "Aposentadoria por invalidez - acidente do trabalho",
  93: "Pensão por morte - acidente do trabalho",
  94: "Auxílio acidente",
  95: "Auxílio suplementar por acidente do trabalho",
  96: "Pensão especial às pessoas atingidas pela hanseníase",
};
