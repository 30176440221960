import { Edge, Node } from "react-flow-renderer/dist/nocss/esm";
import { ObjectOf } from "../../common/types/ObjectOf";
import Kpi from "../types/Kpi";
import NodeData, { ActionNodeData, ProviderNodeData } from "../types/NodeData";
import Proponent from "../types/Proponent";
import ProviderCondition from "../types/ProviderCondition";
import { ProviderOperator } from "../types/ProviderOperator";
import RawNode, { ActionRawNode, ProviderEvaluation, ProviderRawNode, TargetUserRawNode } from "../types/RawNode";
import { getKpisOptions } from "./getKpisOptions";

const getNodeOrigin = (): "cpf" | "cnpj" => {
  return "cpf";
};

const getTargetUserNextId = (proponent: Proponent, edges: Array<Edge>): string =>
  edges.find((edge) => {
    const formattedHandleId = edge.sourceHandle?.split("handle-").join("");
    return formattedHandleId === proponent.id;
  })?.target || "no-id-found";

const getProviderDefaultConditionId = (edges: Array<Edge>, providerNodeId: string) =>
  edges.find((edge) => {
    const formattedHandleId = edge.sourceHandle?.split("handle-default-condition-").join("");
    return formattedHandleId === providerNodeId;
  })?.target || "no-id-found";

const getWarningActionNextId = (nodeId: string, edges: Array<Edge>): string | null => {
  const warningActionNodeSourceHandleId = "handle-warning-" + nodeId;
  const nodeEdge = edges.find((edge) => edge.sourceHandle === warningActionNodeSourceHandleId);
  return nodeEdge?.target || null;
};

const getEvaluationNextId = (condition: ProviderCondition, edges: Array<Edge>): string => {
  return (
    edges.find((edge) => {
      const nodesIdRegex = /handle-|yes-condition-|no-condition-/;
      const formattedHandleId = edge.sourceHandle?.split(nodesIdRegex).join("");
      return formattedHandleId === condition.id;
    })?.target || "no-id-found"
  );
};

const getEvaluation = (
  condition: ProviderCondition,
  kpi: string,
  kpiValueType: string,
  edges: Array<Edge>
): ProviderEvaluation => {
  return {
    condition: kpiValueType === "rating" ? ("in" as ProviderOperator) : condition.operator,
    id: condition.id,
    kpi,
    next_id: getEvaluationNextId(condition, edges),
    value:
      kpiValueType === "integer" || kpiValueType === "decimal" || kpiValueType === "currency"
        ? Number(condition.value)
        : condition.value,
  };
};

export default function getRawNodes(
  nodes: Array<Node<NodeData>>,
  edges: Array<Edge>,
  proponents: Array<Proponent>,
  simulatorsKpis: ObjectOf<Array<Kpi> | undefined>
): Array<RawNode> {
  const rawNodes = nodes.reduce((result: Array<RawNode>, node) => {
    const nodeType = node.data.type;
    if (nodeType === "action") {
      const rawNode: ActionRawNode = {
        action: node.data.action,
        display: {
          x: node.position.x,
          y: node.position.y,
        },
        id: node.id,
        node: "action",
        order: result.length,
        type: node.data.type,
      };
      if ((node.data as ActionNodeData).action === "warning") {
        rawNode.warning_message = (node.data as ActionNodeData).custom_data.warningMessage;
        const nextId = getWarningActionNextId(node.id, edges);
        rawNode.has_next = false;
        if (nextId) {
          rawNode.next_id = nextId;
          rawNode.has_next = true;
        }
      }
      result.push(rawNode);
    }
    if (nodeType === "providers" || nodeType === "global" || !nodeType) {
      const providerNode = node as Node<ProviderNodeData>;
      const selectedKpi = providerNode.data.custom_data.selectedKpi;
      const providerNodeSimulationId = providerNode.data.simulation_id;
      const providerNodeSimulatorKpis = providerNodeSimulationId ? simulatorsKpis[providerNodeSimulationId] : undefined;
      const kpisOptions = getKpisOptions(providerNode.data, providerNodeSimulatorKpis);
      let selectedKpiFriendlyName = "no-name";
      let selectedKpiValueType: string | null = null;
      if (kpisOptions && selectedKpi && kpisOptions[selectedKpi]) {
        selectedKpiFriendlyName = kpisOptions && selectedKpi ? kpisOptions[selectedKpi].label : String(selectedKpi);
        selectedKpiValueType = kpisOptions && selectedKpi ? kpisOptions[selectedKpi].valueType : null;
      }
      const rawNode: ProviderRawNode = {
        default: getProviderDefaultConditionId(edges, node.id),
        display: {
          x: node.position.x,
          y: node.position.y,
        },
        // Se não tiver kpi selecionado, não tem conditions
        evaluations: selectedKpi
          ? providerNode.data.custom_data.conditions.map((condition) =>
              getEvaluation(condition, selectedKpi, selectedKpiValueType as string, edges)
            )
          : [],
        friendly_name: selectedKpiFriendlyName,
        id: node.id,
        node: "provider",
        options: providerNode.data.options,
        order: result.length,
        origin: getNodeOrigin(),
        provider: providerNode.data.provider,
        provider_id: providerNode.data.provider_id,
        selected_kpi: selectedKpi,
        type: node.data.type,
        value_type: selectedKpiValueType || "score",
      };
      if (providerNode.data.options.simulation_type) {
        rawNode.simulation_type = providerNode.data.options.simulation_type;
      }
      if (providerNode.data.options.inputs) {
        rawNode.inputs = providerNode.data.options.inputs;
      }
      if (providerNode.data.simulation_id) {
        rawNode.simulator_id = providerNode.data.simulation_id;
      }
      result.push(rawNode);
    }
    if (nodeType === "start_flow") {
      // Se for o nó de start_flow, mapeia os proponentes (ja que o nó em si não tem estes dados)
      const proponentNodes = proponents.map((proponent, index) => {
        const rawNode: TargetUserRawNode = {
          default: null,
          display: {
            x: node.position.x,
            y: node.position.y,
          },
          id: proponent.id,
          label: "start_flow",
          multiple: proponent.acceptMultiples,
          next: getTargetUserNextId(proponent, edges),
          node: "target_user",
          order: index,
          origin: [...proponent.type],
          required: proponent.mandatory,
          title: proponent.name,
          type: node.data.type,
        };
        return rawNode;
      });
      result.push(...proponentNodes);
    }
    return result;
  }, []);
  rawNodes.sort();

  return rawNodes;
}
