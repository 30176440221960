import { LqdBuilderCard, LqdBusinessIcon, LqdNodeCard, LqdTypography, LqdUserIcon } from "@/liquid-components/src";
import { MoreVert, RadioButtonUnchecked } from "@mui/icons-material";
import { Avatar, Box, CardContent, CardHeader, IconButton } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import "react-flow-renderer/dist/style.css";
import { Handle, NodeProps, Position, useStore, useUpdateNodeInternals } from "react-flow-renderer/nocss";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import FakeHandle from "../../../../../productBuilder/components/Node/FakeHandle";
import "../../../../../productBuilder/components/Node/Node.css";
import NodeIcon from "../../../../../productBuilder/components/NodeIcon";
import "../../../../../productBuilder/components/flow-handle.css";
import { activeNodeChanged } from "../../../../../productBuilder/productBuilderSlice";
import ActionNodeCustomData from "../../../../../productBuilder/types/ActionNodeCustomData";
import NodeData, {
  ActionNodeData,
  ProviderNodeData,
  StartFlowNodeData,
} from "../../../../../productBuilder/types/NodeData";
import { ProviderOperator } from "../../../../../productBuilder/types/ProviderOperator";
import { formatNodeValueByKpiType } from "../../../../../productBuilder/utils/formatNodeValueByKpiType";
import { getKpisOptions } from "../../../../../productBuilder/utils/getKpisOptions";
import {
  getNodeColor,
  getNodeSubtitle,
  getNodeTitle,
  getProviderNodeKpisData,
} from "../../../../../productBuilder/utils/nodeContents";

export default function TemplateNode(props: NodeProps<NodeData>) {
  const { data, id } = props;

  const dispatch = useAppDispatch();
  const connectionNodeId = useStore((state) => state.connectionNodeId);

  const { activeNode, badNodes, nodes, proponents } = useAppSelector((state) => state.productBuilder);
  const { tenantProducts } = useAppSelector((state) => state.products);

  const isTarget = connectionNodeId && connectionNodeId !== id;
  const updateNodeInternals = useUpdateNodeInternals();

  const actionNodeData: ActionNodeData = data as ActionNodeData;
  const providerNodeData: ProviderNodeData = data as ProviderNodeData;
  const startFlowNodeData: StartFlowNodeData = data as StartFlowNodeData;
  const nodeIsAction = Boolean(actionNodeData.action);
  const nodeIsProvider = Boolean(providerNodeData.provider);
  const nodeIsStartFlow = startFlowNodeData.type === "start_flow";

  const kpisOptions = nodeIsProvider ? getKpisOptions(providerNodeData) : {};
  const selectedKpi = providerNodeData.custom_data?.selectedKpi || "";
  const selectedKpiValueType = kpisOptions[selectedKpi]?.valueType || null;

  useEffect(() => {
    // Força um re-render do node caso os proponents ou conditions mudem, para redesenhar as edges
    if (nodeIsProvider || nodeIsStartFlow) updateNodeInternals(id);
  }, [proponents, providerNodeData.custom_data?.conditions]);

  const handleNodeClick = () => {
    const nodeToBeActive = nodes.find((nd) => nd.id === id) || null;
    dispatch(activeNodeChanged(nodeToBeActive));
  };

  const getProductName = (productId: string) => tenantProducts[productId].name;

  const getSubtitle = (nodeData: NodeData): string => {
    if ((nodeData as ProviderNodeData).provider) {
      const providerNodeData = nodeData as ProviderNodeData;
      const providerKpiData = getProviderNodeKpisData(providerNodeData);
      const providerNodeSelectedKpi = providerNodeData.custom_data?.selectedKpi;
      const kpiFriendlyName =
        providerKpiData && providerNodeSelectedKpi
          ? providerKpiData[providerNodeSelectedKpi]
          : getNodeSubtitle(nodeData);
      return kpiFriendlyName; // Se o nó for de provider, renderiza o friendly_name do kpi selecionado
    } else return getNodeSubtitle(nodeData); // Se o nó for de qualquer outro tipo renderiza o subtítulo de getNodeTitle
  };
  const filledProponents = proponents.filter((proponent) => proponent.name && proponent.type);
  const nodeIsActive = activeNode?.id === id;
  const nodeShouldHighlight = Boolean(badNodes.find((id) => id === id));
  const renderBooleanContent = () =>
    (data as ProviderNodeData).custom_data.conditions.map((condition) => (
      <LqdNodeCard // Conteúdo renderizado caso o nó seja do tipo provider, com selected kpi do tipo booleano
        key={condition.id}
        sx={{
          "&:last-child": { mb: 0 },
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          px: 1,
          py: 2,
        }}
      >
        <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", pl: 2 }} textstyle="h5Headline">
          {condition.value ? "Yes" : "No"}
        </LqdTypography>
        <Box sx={{ display: "inline-flex", position: "relative" }}>
          <RadioButtonUnchecked sx={{ color: "rgba(155, 162, 175, 1)" }} />
          <Handle id={`handle-${condition.id}`} position={Position.Right} type="source" />
        </Box>
      </LqdNodeCard>
    ));

  // Conteúdo renderizado caso o nó seja do tipo provider, com selected kpi do tipo não-booleano (integer, decimal, string ou rating)
  const renderDefaultConditionsContent = useCallback(
    () =>
      (data as ProviderNodeData).custom_data.conditions
        // Primeiro filtra as condições para exibir somente as que estão preenchidas
        .filter(
          (condition) =>
            condition.operator && condition.value !== null && condition.value !== undefined && condition.value !== ""
        )
        // Em seguida, renderiza um LqdNodeCard para cada condição
        .map((condition) => (
          <LqdNodeCard
            key={condition.id}
            sx={{
              "&:last-child": { mb: 0 },
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
              px: 1,
              py: 2,
            }}
          >
            <Box>
              <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ pl: 3 }} textstyle="p2Paragraph">
                {condition.operator ? ProviderOperator[condition.operator] : ""}
              </LqdTypography>
              <LqdTypography sx={{ color: "rgba(0, 0, 0, 1)", pl: 3 }} textstyle="c1Caption">
                {formatNodeValueByKpiType(condition.value as string, selectedKpiValueType)}
              </LqdTypography>
            </Box>
            <Box sx={{ position: "relative" }}>
              <RadioButtonUnchecked sx={{ color: "rgba(155, 162, 175, 1)" }} />
              <Handle id={`handle-${condition.id}`} position={Position.Right} type="source" />
            </Box>
          </LqdNodeCard>
        )),
    [data, id, providerNodeData.custom_data?.conditions]
  );
  const classNames = useMemo(() => {
    let className = "";
    switch (true) {
      case nodeShouldHighlight:
        className += " lqd-highlight-card";
        break;
      case nodeIsAction:
        className += " lqd-action-node__" + actionNodeData.action;
        break;
      case nodeIsProvider:
        className += " lqd-provider-node__" + providerNodeData.provider;
        break;
      default:
        className += " lqd-start-flow-node";
        break;
    }
    return className;
  }, [nodeShouldHighlight]);

  const renderNodeByType = () => {
    switch (true) {
      case nodeIsAction:
        return (
          <>
            <FakeHandle isTarget={isTarget} />
            <Handle
              className={isTarget ? "is-target" : ""}
              id={`handle-${id}`}
              position={Position.Left}
              type="target"
            />
            {actionNodeData.action &&
            (actionNodeData.action === "jump" ||
              (actionNodeData.action === "warning" && actionNodeData.custom_data.warningMessage)) ? (
              <CardContent>
                <LqdNodeCard
                  sx={{
                    "&:last-child": { mb: 0 },
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                    pl: 3,
                    pr: 1,
                    py: 2,
                  }}
                >
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="p2Paragraph">
                      {actionNodeData.action === "jump" ? "Product" : null}
                    </LqdTypography>
                    <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="c1Caption">
                      {actionNodeData.action === "jump"
                        ? getProductName((actionNodeData.custom_data as ActionNodeCustomData).jumpToProduct as string)
                        : (actionNodeData.custom_data as ActionNodeCustomData).warningMessage}
                    </LqdTypography>
                  </Box>
                  {actionNodeData.action === "warning" ? (
                    <Box sx={{ display: "inline-flex", pl: 6, position: "relative" }}>
                      <RadioButtonUnchecked sx={{ color: "rgba(155, 162, 175, 1)" }} />
                      <Handle id={`handle-warning-${id}`} position={Position.Right} type="source" />
                    </Box>
                  ) : null}
                </LqdNodeCard>
              </CardContent>
            ) : null}
          </>
        );
      case nodeIsProvider:
        return (
          <>
            <FakeHandle isTarget={isTarget} />
            <Handle
              className={isTarget ? "is-target" : ""}
              id={`handle-${id}`}
              position={Position.Left}
              type="target"
            />
            {providerNodeData.custom_data?.selectedKpi ? (
              <CardContent className="lqd-node__conditions-container">
                {selectedKpiValueType === "boolean" ? renderBooleanContent() : renderDefaultConditionsContent()}
                <LqdNodeCard
                  sx={{
                    "&:last-child": { mb: 0 },
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                    pl: 4,
                    pr: 1,
                    py: 2,
                  }}
                >
                  <Box>
                    <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="p2Paragraph">
                      Default condition
                    </LqdTypography>
                    <LqdTypography textstyle="c1Caption">Any Other</LqdTypography>
                  </Box>
                  <Box sx={{ position: "relative" }}>
                    <RadioButtonUnchecked sx={{ color: "rgba(155, 162, 175, 1)" }} />
                    <Handle id={`handle-default-condition-${id}`} position={Position.Right} type="source" />
                  </Box>
                </LqdNodeCard>
              </CardContent>
            ) : null}
          </>
        );
      default:
        return (
          <CardContent>
            {filledProponents.length ? (
              filledProponents.map((proponent) => (
                <LqdNodeCard
                  key={proponent.id}
                  sx={{
                    "&:last-child": { mb: 0 },
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                    px: 1,
                    py: 2,
                  }}
                >
                  <Box
                    sx={{ backgroundColor: "rgba(249, 249, 249, 1)", borderRadius: "50%", height: "24px", mr: 1, p: 1 }}
                  >
                    {proponent.type[0] === "cpf" ? <LqdUserIcon /> : <LqdBusinessIcon />}
                  </Box>
                  <LqdTypography
                    sx={{ flex: "1", overflow: "hidden", textOverflow: "ellipsis" }}
                    textstyle="p2Paragraph"
                  >
                    {proponent.name}
                  </LqdTypography>
                  <Box sx={{ position: "relative" }}>
                    <RadioButtonUnchecked sx={{ color: "rgba(155, 162, 175, 1)" }} />
                    <Handle id={`handle-${proponent.id}`} position={Position.Right} type="source" />
                  </Box>
                </LqdNodeCard>
              ))
            ) : (
              <Box
                sx={{
                  backgroundColor: "transparent",
                  border: "1px dashed rgba(102, 159, 255, 1)",
                  borderRadius: "20px",
                  color: "rgba(102, 159, 255, 1)",
                  display: "flex",
                  fontSize: "16px",
                  fontWeight: "bold",
                  justifyContent: "center",
                  py: 2,
                }}
              >
                + Add target User
              </Box>
            )}
          </CardContent>
        );
    }
  };

  return (
    <LqdBuilderCard
      className={classNames}
      onClick={handleNodeClick}
      shadow={nodeIsActive.toString()}
      sx={{ backgroundColor: getNodeColor(data), p: 1, width: "308px" }}
    >
      <CardHeader
        action={
          <IconButton>
            <MoreVert />
          </IconButton>
        }
        avatar={
          <Avatar sx={{ backgroundColor: "rgba(255, 255, 255, 1)", color: "rgba(0, 0, 0, 1)" }}>
            <NodeIcon {...data} />
          </Avatar>
        }
        subheader={getSubtitle(data)}
        title={
          <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ fontWeight: 600 }} textstyle="c1Caption">
            {getNodeTitle(data)}
          </LqdTypography>
        }
      />
      {renderNodeByType()}
    </LqdBuilderCard>
  );
}
