import { numberFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { BasicSimulation } from "../../../../../../types/Simulation/Basic/BasicSimulation";

type PDFBasicSimulatorTableRow2Props = {
  simulation: BasicSimulation;
};

const styles = StyleSheet.create({
  allotmentTableColumn: {
    fontSize: "14px",
    width: "285px",
  },
  allotmentTableRow1Container: {
    alignItems: "center",
    borderBottom: "1px solid rgb(224, 226, 230)",
    display: "flex",
    flexDirection: "row",
    height: "92px",
    width: "100%",
  },
  allotmentTableRowText: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    textAlign: "left",
  },
  allotmentTableRowTitle: {
    alignSelf: "flex-start",
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
    textAlign: "left",
  },
  approveLabel: {
    backgroundColor: "rgb(212, 251, 232)",
    borderRadius: "20px",
    color: "rgb(7, 65, 84)",
    fontSize: "14px",
    marginLeft: "100px",
    paddingBottom: "7px",
    paddingLeft: "13px",
    paddingRight: "12px",
    paddingTop: "8px",
  },
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  reproveLabel: {
    backgroundColor: "rgb(255, 218, 209)",
    borderRadius: "20px",
    color: "rgb(118, 11, 70)",
    fontSize: "14px",
    marginLeft: "100px",
    paddingBottom: "7px",
    paddingLeft: "13px",
    paddingRight: "12px",
    paddingTop: "8px",
  },
});

export default function PDFBasicSimulatorTableRow2(props: PDFBasicSimulatorTableRow2Props) {
  const { simulation } = props;

  return (
    <View style={styles.allotmentTableRow1Container}>
      <View style={styles.allotmentTableColumn}>
        <View style={styles.genericFlexColumnContainer}>
          <Text style={styles.allotmentTableRowTitle}>Pagamento mensal pretendido</Text>
          <Text style={styles.allotmentTableRowText}>
            {numberFormatter(simulation.installment_value_desired).formattedToCurrency}
          </Text>
        </View>
      </View>
      <View style={styles.allotmentTableColumn}>
        <View style={styles.genericFlexColumnContainer}>
          <Text style={styles.allotmentTableRowTitle}>Comprometimento de renda</Text>
          <Text
            style={styles.allotmentTableRowText}
          >{`${numberFormatter(simulation.income_commitment).formattedToPercentage}`}</Text>
        </View>
      </View>
      {simulation.income_approval ? (
        <Text style={styles.approveLabel}>Renda suficiente para valor pretendido</Text>
      ) : (
        <Text style={styles.reproveLabel}>Renda insuficiente para valor pretendido</Text>
      )}
    </View>
  );
}
