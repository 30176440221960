import { AnalysisDetailsStep } from "@analysis/types/AnalysisDetails/Analysis/AnalysisDetailsStep";
import { numberFormatter } from "@common/utils/dataFormatter";

const checkforValueCurrency = (step: AnalysisDetailsStep) => {
  switch (step.valueType) {
    case "currency":
      return numberFormatter(Number(step.providerValue)).formattedToCurrency;
    case "decimal":
      return numberFormatter(Number(step.providerValue)).formattedToPercentage;
    default:
      return step.providerValue;
  }
};

export const formattedProviderValue = (step: AnalysisDetailsStep) => {
  switch (typeof step.providerValue) {
    case "boolean":
      return step.providerValue ? "Sim" : "Não";
    case "number":
      return checkforValueCurrency(step);
    case "string":
      return step.providerValue;
    default:
      return "Informação não encontrada";
  }
};
