import { numberFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { SBPESimulationHighlight } from "../../../../../../types/Simulation/SBPE/SBPESimulationHighlight";

type PDFSBPESimulatorFinancingInfoRowProps = {
  highlight: SBPESimulationHighlight;
};

const styles = StyleSheet.create({
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  labelApprove: {
    backgroundColor: "rgb(212, 251, 232)",
    borderRadius: "20px",
    color: "rgb(7, 65, 84)",
    fontSize: "14px",
    paddingBottom: "7px",
    paddingLeft: "13px",
    paddingRight: "12px",
    paddingTop: "8px",
  },
  labelReprove: {
    backgroundColor: "rgb(255, 218, 209)",
    borderRadius: "20px",
    color: "rgb(118, 11, 70)",
    fontSize: "14px",
    paddingBottom: "7px",
    paddingLeft: "13px",
    paddingRight: "12px",
    paddingTop: "8px",
  },
  sbpeTableColumn: {
    width: "300px",
  },
  sbpeTableContainer: {
    alignItems: "center",
    borderBottom: "1px solid rgb(224, 226, 230)",
    display: "flex",
    flexDirection: "row",
    height: "92px",
    justifyContent: "space-between",
    width: "100%",
  },
  sbpeTableRowText: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    textAlign: "left",
  },
  sbpeTableRowTitle: {
    alignSelf: "flex-start",
    color: "rgb(155, 162, 175)",
    fontSize: "13px",
    textAlign: "left",
  },
});

export default function PDFSBPESimulatorFinancingInfoRow(props: PDFSBPESimulatorFinancingInfoRowProps) {
  const { highlight } = props;

  const reachedFinancingValue = highlight.max_financing_value >= highlight.desired_financing_value;

  return (
    <View style={styles.sbpeTableContainer}>
      {highlight.financing_value_limit > highlight.max_financing_value ? (
        <View style={styles.sbpeTableColumn}>
          <View style={styles.genericFlexColumnContainer}>
            <Text style={styles.sbpeTableRowTitle}>Limite disponível de acordo com a renda</Text>
            <Text style={styles.sbpeTableRowText}>
              {numberFormatter(highlight.financing_value_limit).formattedToCurrency}
            </Text>
          </View>
        </View>
      ) : null}
      <View style={styles.sbpeTableColumn}>
        <View style={styles.genericFlexColumnContainer}>
          <Text style={styles.sbpeTableRowTitle}>Limite disponível de acordo com a renda e LTV</Text>
          <Text style={styles.sbpeTableRowText}>
            {numberFormatter(highlight.max_financing_value).formattedToCurrency}
          </Text>
        </View>
      </View>
      <Text style={reachedFinancingValue ? styles.labelApprove : styles.labelReprove}>
        {reachedFinancingValue ? "Atingiu o financiamento bancário" : "Não atingiu o financiamento bancário"}
      </Text>
    </View>
  );
}
