import { numberFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, View } from "@react-pdf/renderer";
import { AllotmentSimHighlight } from "../../../../../../types/Simulation/Allotment/AllotmentSimulationHighlight";
import PDFAllotmentAndBasicSimulatorInfoCard from "../PDFAllotmentAndBasicSimulatorInfoCard";

type PDFAllotmentSimulatorInfoContainerProps = {
  highlight: AllotmentSimHighlight;
};

const styles = StyleSheet.create({
  generalInfoCardContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "40px",
  },
});

export default function PDFAllotmentSimulatorInfoContainer(props: PDFAllotmentSimulatorInfoContainerProps) {
  const { highlight } = props;

  return (
    <View minPresenceAhead={40} style={styles.generalInfoCardContainer}>
      <PDFAllotmentAndBasicSimulatorInfoCard
        title="Valor da proposta"
        value={numberFormatter(highlight.contract_value, "R$", true).formattedToCurrency}
      />
      <PDFAllotmentAndBasicSimulatorInfoCard
        title="Entrada"
        value={numberFormatter(highlight.down_payment, "R$", true).formattedToCurrency}
      />
      <PDFAllotmentAndBasicSimulatorInfoCard
        title={`Financiamento\n pretendido`}
        value={numberFormatter(highlight.sec_loan_amount, "R$", true).formattedToCurrency}
      />
      <PDFAllotmentAndBasicSimulatorInfoCard
        title={`Composição das\n rendas`}
        value={numberFormatter(highlight.monthly_income, "R$", true).formattedToCurrency}
      />
    </View>
  );
}
