import { dateFormatter, numberFormatter } from "@common/utils/dataFormatter";

export const formatTrustpadDashboardCardValue = (value: number | string, type: string, label: string) => {
  if (type === "textDefault") return label; // Value é nulo nesse caso, por isso essa verificação não é feita no switch.
  if (!value) return "Informação não encontrada";

  switch (type) {
    case "boolean":
      return value.toString().toLowerCase() === "true" ? "Sim" : "Não";
    case "currency":
      return numberFormatter(value).formattedToCurrency;
    case "date":
      if (typeof value === "string") return dateFormatter(value).fullDateFromISO;
      else return dateFormatter(value).fullDate;
    case "decimal":
    case "percentage":
      return numberFormatter(value).formattedToPercentage;
    case "integer":
      return Number(value).toString();
    case "textDefault":
      return label;
    default:
      return value.toString();
  }
};
