import { ObjectOf } from "@common/types/ObjectOf";
import { Timestamp } from "@common/types/Timestamp";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { ptBR } from "date-fns/locale";
import { capitalizeString } from "./capitalize";

const MINIMUM_TIMESTAMP = -3786825600; // Timestamp referente ao dia 01/01/1850.
const TIMEZONE = "America/Sao_Paulo";

type DateFormatterResult = {
  fullDate: string;
  fullDateAndTime: string;
  fullDateFromISO: string;
  fullDateNoTimezone: string;
  fullDateText: string;
  fullDateTextTime: string;
  fullTime: string;
  monthYear: string;
  portugueseFullDate: string;
  slashFormattedDate: string;
  yearMonth: string;
};

type StringFormatterResult = {
  formattedAccount: string;
  formattedAddress: string;
  formattedDocument: string;
  formattedPaddedValue: string;
  formattedPhone: string;
  parsedString: string;
};

type NumberFormatterResult = {
  formattedToCurrency: string;
  formattedToPercentage: string;
};

export const dateFormatter = (timestamp: Timestamp | Date | string | null | undefined): DateFormatterResult => {
  if (
    timestamp === null ||
    timestamp === undefined ||
    (typeof timestamp === "number" && timestamp < MINIMUM_TIMESTAMP)
  ) {
    return {
      fullDate: "",
      fullDateAndTime: "",
      fullDateFromISO: "",
      fullDateNoTimezone: "",
      fullDateText: "",
      fullDateTextTime: "",
      fullTime: "",
      monthYear: "",
      portugueseFullDate: "",
      slashFormattedDate: "",
      yearMonth: "",
    };
  }

  let parsedDate;

  switch (true) {
    case typeof timestamp === "string":
      parsedDate = toZonedTime(new Date(timestamp), TIMEZONE);
      break;
    case timestamp instanceof Date:
      parsedDate = toZonedTime(timestamp, TIMEZONE);
      break;
    default:
      parsedDate = toZonedTime(new Date(Number(timestamp)), TIMEZONE);
      break;
  }

  const [year, month, day] =
    typeof timestamp === "string" && timestamp.match(/^\d{4}-\d{2}-\d{2}$/) ? timestamp.split("-").map(Number) : [];
  const parsedDateNoTimezone =
    typeof timestamp === "string" && timestamp.match(/^\d{4}-\d{2}-\d{2}$/)
      ? new Date(year, month - 1, day)
      : new Date(timestamp);

  return {
    fullDate: format(parsedDate, "dd/MM/yyyy", { locale: ptBR }),
    fullDateAndTime: format(parsedDate, "dd/MM/yyyy HH:mm", { locale: ptBR }),
    fullDateFromISO: typeof timestamp === "string" ? format(parsedDate, "dd/MM/yyyy", { locale: ptBR }) : "",
    fullDateNoTimezone: format(parsedDateNoTimezone, "dd/MM/yyyy", { locale: ptBR }),
    fullDateText: format(parsedDate, "dd MMM yyyy", { locale: ptBR }),
    fullDateTextTime: format(parsedDate, "dd MMM yyyy, HH:mm", { locale: ptBR }),
    fullTime: format(parsedDate, "HH:mm", { locale: ptBR }),
    monthYear: format(parsedDate, "MM/yy", { locale: ptBR }),
    portugueseFullDate: format(parsedDate, "dd 'de' MMMM 'de' yyyy", { locale: ptBR }),
    slashFormattedDate: typeof timestamp === "string" ? timestamp.replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1") : "",
    yearMonth: format(parsedDate, "yy-MM/LLLL", { locale: ptBR }).replace(
      /\/(.)/,
      (_, letter) => `/${letter.toUpperCase()}`
    ),
  };
};

export const dateFormatterUtils = {
  isValidDate: (dateString: string): boolean => !!dateFormatterUtils.parseDate(dateString),
  isValidEndDate: (startDateString: string, endDateString: string): boolean => {
    const startDate = dateFormatterUtils.parseDate(startDateString);
    const endDate = dateFormatterUtils.parseDate(endDateString);

    return !!startDate && !!endDate && startDate <= endDate && endDate <= new Date();
  },
  parseDate: (dateString: string): Date | null => {
    if (!dateString || dateString.length !== 10) return null;

    const [day, month, year] = dateString.split("/").map(Number);

    if (!day || !month || !year || isNaN(day) || isNaN(month) || isNaN(year)) return null;

    const date = new Date(year, month - 1, day);

    return date.getDate() === day && date.getMonth() === month - 1 && date.getFullYear() === year ? date : null;
  },
};

export const stringFormatter = (value: string, format?: "CEP" | "CNPJ" | "CPF"): StringFormatterResult => {
  if (!value)
    return {
      formattedAccount: "",
      formattedAddress: "Informação não encontrada",
      formattedDocument: "",
      formattedPaddedValue: "",
      formattedPhone: "",
      parsedString: "",
    };

  let doc: string;

  if (value.length === 11) {
    doc = `${value.slice(0, 3)}.${value.slice(3, 6)}.${value.slice(6, 9)}-${value.slice(-2)}`;
  } else if (value.length === 14) {
    doc = `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(5, 8)}/${value.slice(8, 12)}-${value.slice(-2)}`;
  } else {
    doc = value;
  }

  const formats = {
    CEP: { length: 8, pattern: /^(\d{5})(\d{3})$/, replacement: "$1-$2" },
    CNPJ: { length: 14, pattern: /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, replacement: "$1.$2.$3/$4-$5" },
    CPF: { length: 11, pattern: /^(\d{3})(\d{3})(\d{3})(\d{2})$/, replacement: "$1.$2.$3-$4" },
  };

  return {
    formattedAccount: `${value.split("/")[1]} / ${value.split("/")[0].slice(0, -3)}xx-${value.split("/")[0].slice(-1)}`,
    formattedAddress: `${value.split("-")[0].toUpperCase()} - ${capitalizeString(value.split("-")[1])}`,
    formattedDocument: doc,
    formattedPaddedValue: format
      ? value
          .toString()
          .padStart(formats[format].length, "0")
          .replace(formats[format].pattern, formats[format].replacement)
      : "",
    formattedPhone: value.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "+$1 $2 $3-$4"),
    parsedString: value.replace(/\.|-|\//g, ""),
  };
};

export const numberFormatter = (
  value: number | string,
  currency?: "$" | "R$" | "US$" | "€",
  hideCurrency?: boolean
): NumberFormatterResult => {
  if (!value) {
    return {
      formattedToCurrency: "",
      formattedToPercentage: "",
    };
  }

  const currencyTable: ObjectOf<"BRL" | "EUR" | "USD"> = {
    $: "USD",
    R$: "BRL",
    US$: "USD",
    "€": "EUR",
  };

  return {
    formattedToCurrency: new Intl.NumberFormat("pt-BR", {
      currency: currencyTable[currency || "R$"],
      style: "currency",
    })
      .format(Number(value))
      .replace(/^.{1,3}/, hideCurrency ? "" : "$&"),
    formattedToPercentage: new Intl.NumberFormat("pt-BR", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: "percent",
    }).format(Number(value)),
  };
};
