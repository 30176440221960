import useAnalysisDetails from "@analysis/hooks/useAnalysisDetails";
import { capitalizeString } from "@common/utils/capitalize";
import { dateFormatter } from "@common/utils/dataFormatter";
import { MenuItem } from "@mui/material";
import { Document, usePDF } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { BuilderSimulation } from "../../types/Simulation/Builder/BuilderSimulation";
import { simulationNames } from "../../utils/simulationNames";
import AnalysisDashboardPDFDownloadButton from "./AnalysisDashboardPDFDownloadButton";
import AnalysisDashboardPDFEmailButton from "./AnalysisDashboardPDFEmailButton";
import DashboardPDF from "./DashboardPDF/DashboardPDF";

export type AnalysisDashboardPDFProps = {
  allOptions: Array<string>;
  loading: boolean;
  menuItems: Array<{ label: string; value: string }>;
  pdfInstance: { blob: Blob | null; error: null | string; url: null | string };
  resetOptions: () => void;
  selectedOptions: Array<string>;
  setSelectedOptions: React.Dispatch<React.SetStateAction<Array<string>>>;
};

type AnalysisDashboardPDFButtonsProps = {
  isMobileView?: boolean;
};

const ALL_OPTIONS_LABEL = "Todos";
const TRUSTPAD_LABEL = "Trustpad";

export default function AnalysisDashboardPDFButtons(props: AnalysisDashboardPDFButtonsProps) {
  const { isMobileView } = props;

  const [instance, updateInstance] = usePDF({ document: <Document /> });

  const { analysisDetails, analysisPeers, analysisSteps } = useAnalysisDetails();
  const { groupProducts } = useAppSelector((state) => state.products);
  const { currentUser, users } = useAppSelector((state) => state.users);

  const [loadingPDF, setLoadingPDF] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);

  const dateFormatted = dateFormatter(Date.now()).fullDateAndTime;
  const product = groupProducts[analysisDetails!.productId];

  const analysisPeersParsed = Object.values(analysisDetails!.peers).map((peer) => ({
    ...peer,
    fullName: capitalizeString(peer.fullName),
  }));

  const analysisPeersWithSteps = analysisPeersParsed.map((peer) => ({
    ...peer,
    steps: Object.values(analysisSteps!)
      .flat()
      .filter((step) => step.peerDocument === peer.document),
  }));

  const validSimulations = analysisDetails!.result.simulations
    .filter((simulation) => simulation.simulation_type)
    .filter(
      (simulation) =>
        Object.values(simulation.simulation).length > 0 &&
        (!("description" in simulation.simulation) || simulation.simulation.description)
    );

  const usersToRenderOnPDF = analysisPeersParsed.filter(
    (peer) => selectedOptions.includes(ALL_OPTIONS_LABEL) || selectedOptions.includes(peer.fullName)
  );

  const simulatorsToRenderOnPDF = analysisDetails!.result.simulations.filter(
    (simulation) =>
      selectedOptions.includes(ALL_OPTIONS_LABEL) ||
      selectedOptions.includes(
        simulation.simulation_type === "simulator"
          ? `${simulation.simulation_type}-${(simulation.simulation as BuilderSimulation).name}`
          : simulation.simulation_type
      )
  );

  const shouldRenderTrustpad = selectedOptions.includes(TRUSTPAD_LABEL);
  const footerData = {
    exportedAt: `data de exportação: ${dateFormatted}`,
    exportedBy: `exportado por ${capitalizeString(currentUser?.fullname as string)}`,
  };

  const menuItems = [
    { label: ALL_OPTIONS_LABEL, value: ALL_OPTIONS_LABEL },
    { label: TRUSTPAD_LABEL, value: TRUSTPAD_LABEL },
    ...validSimulations.map((simulation) => ({
      label: simulationNames[simulation.simulation_type] || (simulation.simulation as BuilderSimulation).name,
      value: simulation.simulation_type,
    })),
    ...analysisPeers.map((peer) => ({
      label: capitalizeString(peer.fullName),
      value: capitalizeString(peer.fullName),
    })),
  ];

  useEffect(() => {
    setLoadingPDF(true);

    setTimeout(() => {
      updateInstance(
        <DashboardPDF
          analysisDetails={analysisDetails!}
          currentUser={currentUser!}
          footerData={footerData}
          peersToSimulator={analysisPeersParsed}
          peersWithSteps={analysisPeersWithSteps}
          product={product}
          shouldRenderTrustpad={shouldRenderTrustpad}
          simulatorsToRenderOnPDF={simulatorsToRenderOnPDF}
          users={users}
          usersToRenderOnPDF={usersToRenderOnPDF}
        />
      );
      setTimeout(() => setLoadingPDF(false), 500);
    }, 500);
  }, [selectedOptions]);

  const allOptions = [
    TRUSTPAD_LABEL,
    ...validSimulations
      .filter((simulation) => simulation.simulation_type !== "morar")
      .map((simulation) =>
        simulation.simulation_type === "simulator"
          ? `${simulation.simulation_type}-${(simulation.simulation as BuilderSimulation).name}`
          : simulation.simulation_type
      ),
    ...analysisPeers.map((peer) => capitalizeString(peer.fullName)),
  ];

  const resetOptions = () => {
    setSelectedOptions([]);
  };

  const commonProps: AnalysisDashboardPDFProps = {
    allOptions,
    loading: loadingPDF,
    menuItems,
    pdfInstance: instance,
    resetOptions,
    selectedOptions,
    setSelectedOptions,
  };

  const downloadButton = <AnalysisDashboardPDFDownloadButton {...commonProps} />;
  const emailButton = <AnalysisDashboardPDFEmailButton {...commonProps} />;

  return isMobileView ? (
    <>
      <MenuItem sx={{ margin: 0, padding: 0 }}>{downloadButton}</MenuItem>
      <MenuItem sx={{ margin: 0, padding: 0 }}>{emailButton}</MenuItem>
    </>
  ) : (
    <>
      {downloadButton}
      {emailButton}
    </>
  );
}
