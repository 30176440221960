import { LqdEditIcon, LqdIconButton, LqdTable, LqdToggle, LqdTypography } from "@/liquid-components/src";
import SettingsRouteHeader from "@common/components/SettingsRouteHeader";
import { dateFormatter } from "@common/utils/dataFormatter";
import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { dialogCalled, dialogLoaded, toastCalled } from "../../../common/commonSlice";
import { ToastNotification } from "../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import { createTemplateModalOpened, editTemplateModalOpened } from "../../adminSlice";
import { onLoadTemplates } from "../../adminSliceThunks";
import { adminUpdateTemplate } from "../../api/UpdateTemplate";
import { TemplateResponse } from "../../types/TemplateResponse";
import CreateTemplateForm from "./CreateTemplateForm";
import EditTemplateForm from "./EditTemplateForm";

export default function TemplateConfig() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const segments = useAppSelector((state) => state.admin.segments);
  const templates = useAppSelector((state) => state.admin.templates);

  const [templateUnderEdit, setTemplateUnderEdit] = useState<TemplateResponse | null>(null);

  const handleTemplateStatusChange = (template: TemplateResponse) => {
    setTemplateUnderEdit(template);

    if (template.isActive) {
      dispatch(
        dialogCalled({
          actions: [
            { title: "Voltar" },
            { onClick: handleTemplateStatusChangeConfirmation, title: "Desativar template" },
          ],
          body: `Ao desativar o template, ele não poderá mais ser utilizado para a criação de nenhum
          produto. Deseja prosseguir?`,
          title: "Confirmar",
          type: "default",
        })
      );
    } else {
      handleTemplateStatusChangeConfirmation();
    }
  };

  const handleTemplateStatusChangeConfirmation = async () => {
    dispatch(dialogLoaded(true));

    const isActive = templateUnderEdit?.isActive;

    try {
      if (templateUnderEdit) {
        const requestBody = {
          edges: templateUnderEdit.edges,
          is_active: !isActive,
          name: templateUnderEdit.name,
          providers: templateUnderEdit.providers,
          rules: templateUnderEdit.rules,
          segments: templateUnderEdit.segments,
        };

        await adminUpdateTemplate(templateUnderEdit.templateId, requestBody);
        await dispatch(onLoadTemplates());

        const notification: ToastNotification = {
          message: `Template ${isActive ? "desativado" : "ativado"} com sucesso!`,
          type: "success",
        };

        dispatch(toastCalled(notification));
        setTemplateUnderEdit({ ...templateUnderEdit, isActive: !isActive });
        dispatch(dialogCalled(null));
      }
    } catch (error) {
      handleLiquidErrors(error);
    }
  };

  const tableContent = Object.values(templates).map((template) => {
    const firstThreeSegments = template.segments.slice(0, 3);
    const extraSegmentsLength = template.segments.length - firstThreeSegments.length;

    return [
      {
        primaryValue: template.name,
        tertiaryValue: {
          method: () => dispatch(editTemplateModalOpened({ step: 1, template })),
          text: "Editar",
        },
      },
      {
        primaryValue: `${template.country}/${template.currency}`,
      },
      {
        primaryValue: template.segments.length > 9 ? template.segments.length : "0" + template.segments.length,
        tertiaryValue: {
          method: () => dispatch(editTemplateModalOpened({ step: 2, template })),
          text: "Editar",
        },
        tooltip: {
          body:
            firstThreeSegments.length > 0 ? (
              <>
                {firstThreeSegments.map((segmentId, index) => (
                  <Box key={`${segmentId}-${index}`}>{segments[segmentId].name}</Box>
                ))}
                {extraSegmentsLength > 0 ? <Box>{`+ ${extraSegmentsLength} outros`}</Box> : null}
              </>
            ) : (
              "Nenhum segmento"
            ),
          header: "Segmentos",
        },
      },
      {
        primaryValue: dateFormatter(template.createdAt).fullDateText,
        tertiaryValue: dateFormatter(template.createdAt).fullTime,
      },
      {
        primaryValue: (
          <LqdToggle active={template.isActive} onChange={() => handleTemplateStatusChange(template)} renderText />
        ),
      },
      {
        primaryValue: (
          <LqdIconButton
            onClick={() => dispatch(editTemplateModalOpened({ step: 3, template }))}
            sx={{ ml: -3, mt: -1 }}
            type="ghostIcon"
          >
            <LqdEditIcon />
          </LqdIconButton>
        ),
      },
    ];
  });

  return (
    <>
      <SettingsRouteHeader
        buttonMethod={() => dispatch(createTemplateModalOpened())}
        buttonTitle="Adicionar um template"
        title="Templates"
        tooltipBody="Crie um template para facilitar a criação de um Produto, definindo regras."
        tooltipHeader="Adicionar um template"
      />
      {Object.values(templates).length > 0 ? (
        <Stack sx={{ alignItems: "center", justifyContent: "center", mt: 2 }}>
          <Stack sx={{ width: "87.5%" }}>
            <LqdTable<TemplateResponse>
              height="450px"
              tableContent={tableContent}
              tableHeader={["Template", "Moeda", "Segmentos", "Criação", "Status", ""]}
            />
          </Stack>
        </Stack>
      ) : (
        <LqdTypography color="secondary" sx={{ textAlign: "center" }} textstyle="h5Headline">
          Nenhum template foi criado até o momento.
        </LqdTypography>
      )}
      <CreateTemplateForm />
      <EditTemplateForm />
    </>
  );
}
