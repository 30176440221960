import { capitalizeString } from "@common/utils/capitalize";
import { stringFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { PeerData } from "../../../../../types/AnalysisDetails/PeerData/PeerData";
import PDFTableIconGraySVG from "../PDFTableIconGraySVG";

type PDFLiquidpassBlockedSerasaProps = {
  user: PeerData;
};

const styles = StyleSheet.create({
  liquidPassWarningContainer: {
    alignItems: "center",
    border: "1px solid rgb(222, 225, 229)",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "row",
    marginTop: "24px",
    width: "100%",
  },
  liquidPassWarningText: {
    color: "rgb(127, 135, 152)",
    fontSize: "13.5px",
    paddingBottom: "22px",
    paddingLeft: "15px",
    paddingRight: "24px",
    paddingTop: "22px",
  },
});

export default function PDFLiquidpassBlockedSerasa(props: PDFLiquidpassBlockedSerasaProps) {
  const { user } = props;

  return (
    <View minPresenceAhead={40} style={styles.liquidPassWarningContainer}>
      <PDFTableIconGraySVG />
      <Text style={styles.liquidPassWarningText}>
        {`A consulta ao ${stringFormatter(user.document).parsedString.length === 11 ? "CPF" : "CNPJ"} ${
          document
        } foi bloqueada pelo usuário ${capitalizeString(user.fullName)}.`}
      </Text>
    </View>
  );
}
