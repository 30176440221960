import { numberFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, View } from "@react-pdf/renderer";
import { OpenCreditProviderData } from "../../../../../types/AnalysisDetails/OpenCredit/OpenCreditProviderData";
import PDFMonetaryGeneralInfoCard from "../PDFMonetaryGeneralInfoCard";

type PDFOpenCreditFinalInfoContainerProps = {
  provider: OpenCreditProviderData;
};

export default function PDFOpenCreditFinalInfoContainer({ provider }: PDFOpenCreditFinalInfoContainerProps) {
  const styles = StyleSheet.create({
    generalInfoCardContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "24px 0",
    },
    generalInfoText: {
      color: "rgb(22, 53, 57)",
      fontSize: "24px",
      paddingBottom: "40px",
      paddingTop: "40px",
    },
  });

  return (
    <View minPresenceAhead={40}>
      <View style={styles.generalInfoCardContainer}>
        <PDFMonetaryGeneralInfoCard
          size="small"
          title="Risco Total"
          value={numberFormatter(provider.risk_total_value).formattedToCurrency}
        />
        <PDFMonetaryGeneralInfoCard
          size="small"
          title="Responsabilidade Total"
          value={numberFormatter(provider.responsability_total_value).formattedToCurrency}
        />
        <PDFMonetaryGeneralInfoCard
          size="small"
          title="Créditos a Liberar"
          value={numberFormatter(provider.credits_to_be_issued).formattedToCurrency}
        />
        <PDFMonetaryGeneralInfoCard
          size="small"
          title="Risco Indireto"
          value={numberFormatter(provider.indirect_risk).formattedToCurrency}
        />
      </View>
    </View>
  );
}
