import { capitalizeString } from "@common/utils/capitalize";
import { dateFormatter, numberFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { SerasaCNPJNotaryResponse } from "../../../../../types/AnalysisDetails/SerasaCNPJ/SerasaCNPJNotaryResponse";
import PDFTableIconOrangeSVG from "../PDFTableIconOrangeSVG";

type PDFCNPJSerasaProtestsTableProps = {
  currentPage: number;
  lastPage: number;
  tableData: Array<SerasaCNPJNotaryResponse>;
  tableLength: number;
  tableOccurrences?: number;
  tableValue?: number;
};

const styles = StyleSheet.create({
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  genericFlexRowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  tableCount: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    paddingLeft: "24px",
  },
  tableFullLabel: {
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
    padding: "24px",
  },
  tableHeaderContainer: {
    alignItems: "center",
    backgroundColor: "rgb(250, 250, 251)",
    borderTop: "none",
    display: "flex",
    flexDirection: "row",
    height: "60px",
  },
  tableInnerContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "95%",
  },
  tableOuterContainer: {
    border: "1px solid rgb(222, 225, 229)",
    borderRadius: "20px",
    marginTop: "24px",
    paddingTop: "25px",
    width: "100%",
  },
  tableOuterContainer2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  tableResults: {
    color: "rgb(127, 135, 152)",
    fontSize: "14px",
    paddingLeft: "8px",
    paddingTop: "5px",
  },
  tableRowContainer: {
    alignItems: "center",
    borderTop: "1px solid rgb(222, 225, 229)",
    display: "flex",
    flexDirection: "row",
    height: "72px",
  },
  tableTitle: {
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
    paddingLeft: "24px",
  },
  tableTotalTitle: {
    alignSelf: "flex-end",
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
  },
  tableTotalValue: {
    color: "rgb(255, 181, 71)",
    fontSize: "20px",
  },
  tableWithFourColumns1: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "45%",
  },
  tableWithFourColumns2: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "25%",
  },
  tableWithFourColumns3: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "15%",
  },
  tableWithFourColumns4: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "15%",
  },
  totalValueContainer: {
    display: "flex",
    flexDirection: "column",
    marginRight: "24px",
  },
});

export default function PDFCNPJSerasaProtestsTable(props: PDFCNPJSerasaProtestsTableProps) {
  const { currentPage, lastPage, tableData, tableLength, tableOccurrences, tableValue } = props;

  const rangeStart = currentPage * 16 - 15;
  const rangeEnd = currentPage === lastPage ? tableLength : currentPage * 16;

  return (
    <View minPresenceAhead={40} style={styles.tableOuterContainer}>
      <View style={styles.tableOuterContainer2}>
        <View style={styles.genericFlexRowContainer}>
          <PDFTableIconOrangeSVG />
          <View style={styles.genericFlexColumnContainer}>
            <Text style={styles.tableTitle}>Protestos</Text>
            {tableLength > 0 ? (
              <View style={styles.genericFlexRowContainer}>
                <Text style={styles.tableCount}>{tableOccurrences || 0}</Text>
                {tableLength > 16 ? (
                  <Text style={styles.tableResults}>{`(exibindo resultados ${rangeStart} a ${rangeEnd})`}</Text>
                ) : null}
              </View>
            ) : (
              <Text style={styles.tableCount}>0</Text>
            )}
          </View>
        </View>
        <View style={styles.totalValueContainer}>
          <Text style={styles.tableTotalTitle}>Valor total</Text>
          <Text style={styles.tableTotalValue}>
            {tableValue ? numberFormatter(tableValue).formattedToCurrency : "R$ 0,00"}
          </Text>
        </View>
      </View>
      <Text style={styles.tableFullLabel}>Detalhes dos protestos mais recentes de cada tipo:</Text>
      <View style={styles.tableInnerContainer}>
        <View style={styles.tableHeaderContainer}>
          <Text style={styles.tableWithFourColumns1}>Cartório</Text>
          <Text style={styles.tableWithFourColumns2}>Local</Text>
          <Text style={styles.tableWithFourColumns3}>Data</Text>
          <Text style={styles.tableWithFourColumns4}>Valor</Text>
        </View>
        {tableData?.map((protest, index) => (
          <View key={`${protest.officeName}-${index}`} style={styles.tableRowContainer}>
            <Text style={styles.tableWithFourColumns1}>{protest.officeNumber || "Informação não encontrada"}</Text>
            <Text style={styles.tableWithFourColumns2}>
              {protest.city && protest.federalUnit
                ? `${capitalizeString(protest.city)}, ${protest.federalUnit}`
                : "Informação não encontrada"}
            </Text>
            <Text style={styles.tableWithFourColumns3}>
              {dateFormatter(protest.occurrenceDate).slashFormattedDate || "Informação não encontrada"}
            </Text>
            <Text style={styles.tableWithFourColumns4}>
              {protest.amount ? numberFormatter(protest.amount).formattedToCurrency : "Informação não encontrada"}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
}
