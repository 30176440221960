import { LqdNotesIcon } from "@/liquid-components/src";
import { dateFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { AnalysisCommentObservation } from "../../../../../types/AnalysisDetails/Analysis/AnalysisDetailsComments";
import PDFTrustpadClockSVG from "./StepSVG/PDFTrustpadClockSVG";

type PDFTrustpadAnalysisCommentsProps = {
  comment: AnalysisCommentObservation | null;
};

const styles = StyleSheet.create({
  textCommentNotNull: {
    color: "rgb(127, 135, 152)",
    fontSize: "14px",
    width: "100%",
    wordBreak: "break-word",
    wordWrap: "break-word",
  },
  textCommentNull: {
    color: "rgb(127, 135, 152)",
    fontSize: "14px",
  },
  textCommentTitleWithNoSpacing: {
    color: "rgb(155, 162, 175)",
    fontSize: "12px",
  },
  viewInnerContainerNotNull: {
    marginLeft: "12px",
    width: "100%",
  },
  viewInnerContainerNull: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "12px",
    width: "100%",
  },
  viewOuterContainerNotNull: {
    backgroundColor: "rgb(249, 249, 250)",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    marginBottom: "12px",
    paddingHorizontal: "12px",
    paddingVertical: "12px",
    width: "100%",
  },
  viewOuterContainerNull: {
    backgroundColor: "rgb(249, 249, 250)",
    boxSizing: "border-box",
    display: "flex",
    paddingHorizontal: "12px",
    paddingVertical: "12px",
    width: "100%",
  },
  viewSecondInnerContainerNotNull: {
    display: "flex",
    width: "100%",
  },
});

export default function PDFTrustpadAnalysisComments(props: PDFTrustpadAnalysisCommentsProps) {
  const { comment } = props;

  const timestamp =
    comment && comment.timestamp && comment.user
      ? `${dateFormatter(comment.timestamp).fullDateAndTime} por ${comment.user}`
      : "Não especificado.";

  return comment ? (
    <View minPresenceAhead={40} style={styles.viewOuterContainerNotNull}>
      <PDFTrustpadClockSVG />
      <View style={styles.viewInnerContainerNotNull}>
        <View style={styles.viewSecondInnerContainerNotNull}>
          <Text style={styles.textCommentTitleWithNoSpacing}>{timestamp}</Text>
        </View>
        <Text style={styles.textCommentNotNull}>{comment.comment}</Text>
      </View>
    </View>
  ) : (
    <View minPresenceAhead={40} style={styles.viewOuterContainerNull}>
      <LqdNotesIcon color="rgb(127, 135, 152)" />
      <View style={styles.viewInnerContainerNull}>
        <Text style={styles.textCommentTitleWithNoSpacing}>Observação</Text>
        <Text style={styles.textCommentNull}>Não especificado.</Text>
      </View>
    </View>
  );
}
