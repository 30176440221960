import { AnalysisDetailsStep } from "@analysis/types/AnalysisDetails/Analysis/AnalysisDetailsStep";
import { ObjectOf } from "@common/types/ObjectOf";
import { numberFormatter } from "@common/utils/dataFormatter";

const conditionDictionary: ObjectOf<string> = {
  "!=": "diferente de",
  "<": "menor que",
  "<=": "igual ou menor que",
  "=": "igual a",
  "==": "igual a",
  ">": "maior que",
  ">=": "igual ou maior que",
  in: "está contido em",
};

const formatRatingString = (valueString: string) => {
  return valueString
    .toUpperCase()
    .split(",")
    .map((char, index, self) => (index !== self.length - 1 ? `${char}, ` : char))
    .join("");
};

const validateCondition = (condition: string | undefined) => {
  if (!condition) return "Condição não encontrada";

  return condition;
};

const formatStepValue = (step: AnalysisDetailsStep) => {
  if (!step.value) return "";

  if (step.valueType === "rating") {
    return formatRatingString(step.value.toString());
  }

  return step.value;
};

export const formattedCondition = (step: AnalysisDetailsStep) => {
  if (!step.condition) {
    return "Condição não encontrada";
  }

  switch (step.valueType) {
    case "boolean":
      return null;
    case "currency":
      return `• ${conditionDictionary[step.condition]} ${numberFormatter(Number(step.value)).formattedToCurrency}`;
    case "integer":
      return `• ${conditionDictionary[step.condition]} ${step.value}`;
    case "rating":
      return `• ${validateCondition(conditionDictionary[step.condition])} ${formatStepValue(step)}`;
    case "string":
      if (step.result) {
        return `• ${validateCondition(conditionDictionary[step.condition])} ${formatStepValue(step)}`;
      }

      return "Condição não encontrada";
    case "decimal": {
      return `• ${conditionDictionary[step.condition]} ${numberFormatter(Number(step.value)).formattedToPercentage}`;
    }
    default:
      return `• ${validateCondition(conditionDictionary[step.condition])} ${formatStepValue(step)}`;
  }
};
