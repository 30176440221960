import { numberFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { SBPESimulationHighlight } from "../../../../../../types/Simulation/SBPE/SBPESimulationHighlight";

type PDFSBPESimulatorOutstandingAmountInfoRowProps = {
  highlight: SBPESimulationHighlight;
};

const styles = StyleSheet.create({
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  labelWarning: {
    backgroundColor: "rgb(255, 231, 165)",
    borderRadius: "20px",
    color: "rgb(122, 60, 13)",
    fontSize: "14px",
    paddingBottom: "7px",
    paddingLeft: "13px",
    paddingRight: "12px",
    paddingTop: "8px",
  },
  sbpeTableColumn: {
    width: "300px",
  },
  sbpeTableContainer: {
    alignItems: "center",
    borderBottom: "1px solid rgb(224, 226, 230)",
    display: "flex",
    flexDirection: "row",
    height: "92px",
    justifyContent: "space-between",
    width: "100%",
  },
  sbpeTableOuterContainer: {
    border: "1px solid rgb(224, 226, 230)",
    borderRadius: "20px",
    marginTop: "40px",
    paddingHorizontal: "24px",
    width: "100%",
  },
  sbpeTableRowText: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    textAlign: "left",
  },
  sbpeTableRowTitle: {
    alignSelf: "flex-start",
    color: "rgb(155, 162, 175)",
    fontSize: "13px",
    textAlign: "left",
  },
});

export default function PDFSBPESimulatorOutstandingAmountInfoRow(props: PDFSBPESimulatorOutstandingAmountInfoRowProps) {
  const { highlight } = props;

  const hasBankIncrease = highlight.bank_max_inc > 0;
  const hasBuildingIncrease = highlight.building_payment_max_inc > 0;

  return (
    <View minPresenceAhead={40} style={styles.sbpeTableOuterContainer}>
      <View style={styles.sbpeTableContainer}>
        <View style={styles.sbpeTableColumn}>
          <View style={styles.genericFlexColumnContainer}>
            <Text style={styles.sbpeTableRowTitle}>Valor em aberto</Text>
            <Text style={styles.sbpeTableRowText}>
              {numberFormatter(highlight.outstanding_amount).formattedToCurrency}
            </Text>
          </View>
        </View>
        {hasBankIncrease ? (
          <>
            <View style={styles.sbpeTableColumn}>
              <View style={styles.genericFlexColumnContainer}>
                <Text style={styles.sbpeTableRowTitle}>Aumento máximo disponível</Text>
                <Text style={styles.sbpeTableRowText}>
                  {numberFormatter(highlight.bank_max_inc).formattedToCurrency}
                </Text>
              </View>
            </View>
            <Text style={styles.labelWarning}>Aumento disponível no financiamento</Text>
          </>
        ) : null}
        {hasBuildingIncrease ? (
          <>
            <View style={styles.sbpeTableColumn}>
              <View style={styles.genericFlexColumnContainer}>
                <Text style={styles.sbpeTableRowTitle}>Aumento máximo disponível</Text>
                <Text style={styles.sbpeTableRowText}>
                  {numberFormatter(highlight.building_payment_max_inc).formattedToCurrency}
                </Text>
              </View>
            </View>
            <Text style={styles.labelWarning}>Aumento disponível durante a obra</Text>
          </>
        ) : null}
      </View>
    </View>
  );
}
