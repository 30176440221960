import { capitalizeString } from "@common/utils/capitalize";
import { dateFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { LiquidPassProfessionalData } from "../../../../../types/AnalysisDetails/LiquidPass/LiquidPassProfessionalData";
import PDFTableIconOrangeSVG from "../PDFTableIconOrangeSVG";

type PDFLiquidpassProfessionalDataTableProps = {
  currentPage: number;
  lastPage: number;
  tableData: Array<LiquidPassProfessionalData>;
  tableLength: number;
};

const styles = StyleSheet.create({
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  genericFlexRowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  tableCount: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    paddingLeft: "24px",
  },
  tableFullLabel: {
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
    padding: "24px",
  },
  tableHeaderContainer: {
    alignItems: "center",
    backgroundColor: "rgb(250, 250, 251)",
    borderTop: "none",
    display: "flex",
    flexDirection: "row",
    height: "60px",
  },
  tableInnerContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "95%",
  },
  tableOuterContainer: {
    border: "1px solid rgb(222, 225, 229)",
    borderRadius: "20px",
    marginTop: "24px",
    paddingTop: "25px",
    width: "100%",
  },
  tableResults: {
    color: "rgb(127, 135, 152)",
    fontSize: "14px",
    paddingLeft: "8px",
    paddingTop: "5px",
  },
  tableRowContainer: {
    alignItems: "center",
    borderTop: "1px solid rgb(222, 225, 229)",
    display: "flex",
    flexDirection: "row",
    height: "72px",
  },
  tableTitle: {
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
    paddingLeft: "24px",
  },
  tableTotalTitle: {
    alignSelf: "flex-end",
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
  },
  tableTotalValue: {
    color: "rgb(255, 181, 71)",
    fontSize: "20px",
  },
  tableWithFiveColumns1: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "30%",
  },
  tableWithFiveColumns2: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "20%",
  },
  tableWithFiveColumns3: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "18%",
  },
  tableWithFiveColumns4: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "16%",
  },
  tableWithFiveColumns5: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "16%",
  },
  tableWithoutLabelTitle: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingTop: "40px",
  },
});

export default function PDFLiquidpassProfessionalDataTable(props: PDFLiquidpassProfessionalDataTableProps) {
  const { currentPage, lastPage, tableData, tableLength } = props;

  const rangeStart = currentPage * 16 - 15;
  const rangeEnd = currentPage === lastPage ? tableLength : currentPage * 16;

  return (
    <View minPresenceAhead={40} style={styles.tableOuterContainer}>
      <View style={styles.genericFlexRowContainer}>
        <PDFTableIconOrangeSVG />
        <View style={styles.genericFlexColumnContainer}>
          <Text style={styles.tableTitle}>Dados profissionais</Text>
          {tableLength > 0 ? (
            <View style={styles.genericFlexRowContainer}>
              <Text style={styles.tableCount}>{tableLength}</Text>
              {tableLength > 16 ? (
                <Text style={styles.tableResults}>{`(exibindo resultados ${rangeStart} a ${rangeEnd})`}</Text>
              ) : null}
            </View>
          ) : (
            <Text style={styles.tableCount}>0</Text>
          )}
        </View>
      </View>
      <Text style={styles.tableFullLabel}>Histórico de dados profissionais:</Text>
      <View style={styles.tableInnerContainer}>
        <View style={styles.tableHeaderContainer}>
          <Text style={styles.tableWithFiveColumns1}>Empresa</Text>
          <Text style={styles.tableWithFiveColumns2}>CNPJ</Text>
          <Text style={styles.tableWithFiveColumns3}>Profissão</Text>
          <Text style={styles.tableWithFiveColumns4}>Admissão</Text>
          <Text style={styles.tableWithFiveColumns5}>Demissão</Text>
        </View>
        {tableData.map((pData, index) => (
          <View key={`${pData.company_name}-${index}`} style={styles.tableRowContainer}>
            <Text style={styles.tableWithFiveColumns1}>
              {pData.company_name ? capitalizeString(pData.company_name) : "Informação não encontrada"}
            </Text>
            <Text style={styles.tableWithFiveColumns2}>{pData.company_document || "Informação não encontrada"}</Text>
            <Text style={styles.tableWithFiveColumns3}>{pData.description || "Informação não encontrada"}</Text>
            <Text style={styles.tableWithFiveColumns4}>
              {pData.admission_date ? dateFormatter(pData.admission_date).fullDate : "Informação não encontrada"}
            </Text>
            <Text style={styles.tableWithFiveColumns5}>
              {pData.demission_date ? dateFormatter(pData.demission_date).fullDate : "Informação não encontrada"}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
}
