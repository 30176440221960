import { numberFormatter } from "@common/utils/dataFormatter";

export const formatNodeValueByKpiType = (value: number | string, valueType: string | null): string => {
  switch (valueType) {
    case "currency":
      return numberFormatter(Number(value)).formattedToCurrency;
    case "decimal":
      return numberFormatter(Number(value)).formattedToPercentage;
    case "integer":
      return Number(value).toString();
    case "rating":
      return (value as string)
        .split(",")
        .map((value, index) => `${index > 0 ? " " : ""}${value.toUpperCase()}`)
        .join("");
    default:
      return value.toString();
  }
};
