import { numberFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { AllotmentSimHighlight } from "../../../../../../types/Simulation/Allotment/AllotmentSimulationHighlight";

type PDFAllotmentSimulatorTableRow3Props = {
  highlight: AllotmentSimHighlight;
};

const styles = StyleSheet.create({
  allotmentTableColumn: {
    marginRight: "30px",
    width: "285px",
  },
  allotmentTableRow3Container: {
    alignItems: "center",
    borderBottom: "1px solid rgb(224, 226, 230)",
    display: "flex",
    flexDirection: "row",
    height: "92px",
    width: "100%",
  },
  allotmentTableRowText: {
    color: "rgb(33, 36, 42)",
    fontSize: "18px",
    textAlign: "left",
  },
  allotmentTableRowTitle: {
    alignSelf: "flex-start",
    color: "rgb(155, 162, 175)",
    fontSize: "12px",
    textAlign: "left",
  },
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
});

export default function PDFAllotmentSimulatorTableRow3(props: PDFAllotmentSimulatorTableRow3Props) {
  const { highlight } = props;

  return (
    <View style={styles.allotmentTableRow3Container}>
      {highlight.ltv_approval === false ? (
        <View style={styles.allotmentTableColumn}>
          <View style={styles.genericFlexColumnContainer}>
            <Text style={styles.allotmentTableRowTitle}>
              Aumento de entrada necessário para enquadrar no LTV pretendido
            </Text>
            <Text style={styles.allotmentTableRowText}>
              {numberFormatter(highlight.desired_entry_inc).formattedToCurrency}
            </Text>
          </View>
        </View>
      ) : null}
      {highlight.income_approval === false ? (
        <View style={styles.allotmentTableColumn}>
          <View style={styles.genericFlexColumnContainer}>
            <Text style={styles.allotmentTableRowTitle}>
              Aumento de renda necessário para enquadrar no CR pretendido
            </Text>
            <Text style={styles.allotmentTableRowText}>
              {numberFormatter(highlight.desired_income_inc).formattedToCurrency}
            </Text>
          </View>
        </View>
      ) : null}
      <View style={styles.allotmentTableColumn}>
        <View style={styles.genericFlexColumnContainer}>
          <Text style={styles.allotmentTableRowTitle}>Capacidade máxima de financiamento para o prazo pretendido</Text>
          <Text style={styles.allotmentTableRowText}>
            {numberFormatter(highlight.desired_max_debt_capacity).formattedToCurrency}
          </Text>
        </View>
      </View>
    </View>
  );
}
