import { dateFormatter, numberFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { OpenCreditProviderData } from "../../../../../types/AnalysisDetails/OpenCredit/OpenCreditProviderData";
import PDFMonetaryGeneralInfoCard from "../PDFMonetaryGeneralInfoCard";
import PDFOpenCreditInfoCard from "../PDFOpenCreditInfoCard";

type PDFOpenCreditGeneralInfoContainerProps = {
  provider: OpenCreditProviderData;
};

export default function PDFOpenCreditGeneralInfoContainer({ provider }: PDFOpenCreditGeneralInfoContainerProps) {
  const styles = StyleSheet.create({
    generalInfoCardContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: "24px",
    },
    generalInfoText: {
      color: "rgb(22, 53, 57)",
      fontSize: "24px",
      paddingBottom: "20px",
      paddingTop: "40px",
    },
    smallInfoText: {
      color: "rgb(155, 162, 175)",
      fontSize: "14px",
      paddingBottom: "24px",
    },
  });

  const baseDateParts = provider.base_data.split("-");
  const baseDate = new Date();
  baseDate.setMonth(Number(baseDateParts[1]) - 1);
  baseDate.setDate(1);

  return (
    <View minPresenceAhead={40}>
      <Text style={styles.generalInfoText}>Open Credit</Text>
      <Text style={styles.smallInfoText}>Data de referência: {dateFormatter(baseDate).monthYear}</Text>
      <View style={styles.generalInfoCardContainer}>
        <PDFOpenCreditInfoCard title="Quantidade de Instituições Financeiras" value={provider.institutions_quantity} />
        <PDFOpenCreditInfoCard
          title="Operações amparadas por sub judice"
          value={provider.subjudice_operations_quantity}
        />
      </View>
      <View style={styles.generalInfoCardContainer}>
        <PDFMonetaryGeneralInfoCard
          size="medium"
          title="Carteira de Crédito"
          value={numberFormatter(provider.wallet).formattedToCurrency}
        />
        <PDFMonetaryGeneralInfoCard
          size="medium"
          title="Carteira Ativa"
          value={numberFormatter(provider.active_wallet).formattedToCurrency}
        />
        <PDFMonetaryGeneralInfoCard
          size="medium"
          title="Prejuízo"
          value={numberFormatter(provider.prejudice).formattedToCurrency}
        />
      </View>
    </View>
  );
}
