import { LqdButton, LqdCalendarIcon, LqdSearch, LqdTypography } from "@/liquid-components/src";
import { dateFormatter } from "@common/utils/dataFormatter";
import { normalizeText } from "@common/utils/normalizeText";
import { Box } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { dialogCalled, dialogLoaded, toastCalled } from "../../../common/commonSlice";
import { ToastNotification } from "../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import { onLoadProducts } from "../../adminSliceThunks";
import { adminRegisterProjectCompletionDate } from "../../api/RegisterProjectCompletionDate";
import SuperTenantCard from "../SuperTenantCard";
import BuildingDateInput from "./BuildingDateInput";
import BuildingDateProductCard from "./BuildingDateProductCard";

export default function BuildingDateRegistration() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const currentSelectedTenant = useAppSelector((state) => state.admin.currentSelectedTenant);
  const productsList = Object.values(useAppSelector((state) => state.admin.products)).filter(
    (product) => product.tenantCode === currentSelectedTenant.code
  );
  const { dialogLoading } = useAppSelector((state) => state.common);

  const [projectCompletionDate, setProjectCompletionDate] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedProducts, setSelectedProducts] = useState<Array<string>>([]);

  const filteredProducts = productsList.filter((product) =>
    normalizeText(product.name).includes(normalizeText(searchTerm))
  );

  const formatDate = (date: string, format: string, lang: "BR" | "US"): string => {
    if (!date) return "";

    let updatedDate;

    switch (lang) {
      case "BR":
        updatedDate = date.slice(0, 2) + format + date.slice(2, 4) + format + date.slice(4, 8);
        break;
      case "US":
        updatedDate = date.slice(4, 8) + format + date.slice(2, 4) + format + date.slice(0, 2);
        break;
    }

    return updatedDate;
  };

  const isTimeDiffAvailable = (date: string): boolean => {
    const dateNow = new Date().getTime();
    const projectDate = formatDate(date, "/", "US");
    const projectDateTime = new Date(projectDate).getTime();
    const timeDiff = projectDateTime - dateNow;

    return timeDiff > 0;
  };

  const onSubmitRegisterDate = async () => {
    dispatch(dialogLoaded(true));

    try {
      const updatedDate = formatDate(projectCompletionDate, "-", "US");

      await adminRegisterProjectCompletionDate(selectedProducts, updatedDate, currentSelectedTenant.code);
      await dispatch(onLoadProducts());

      const notification: ToastNotification = {
        message: "Data cadastrada com sucesso.",
        type: "success",
      };

      dispatch(toastCalled(notification));

      dispatch(dialogCalled(null));
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      setSelectedProducts([]);
      dispatch(dialogLoaded(false));
    }
  };

  const handleSelectProduct = (productId: string) => {
    if (selectedProducts.includes(productId)) {
      const filteredProductsIds = selectedProducts.filter((selectedProductId) => selectedProductId !== productId);
      setSelectedProducts(filteredProductsIds);
    } else {
      setSelectedProducts([...selectedProducts, productId]);
    }
  };

  const handleOpenModal = () => {
    const productsToVerify = productsList.filter((product) => selectedProducts.includes(product.productId));
    const anyProductHasDate = productsToVerify.some((product) => product.projectCompletionDate);
    const productsThatHaveDate = productsToVerify.filter((product) => product.projectCompletionDate);

    if (anyProductHasDate) {
      dispatch(
        dialogCalled({
          actions: [{ title: "Voltar" }, { onClick: onSubmitRegisterDate, title: "Cadastrar nova data" }],
          body:
            selectedProducts.length > 1
              ? "Alguns produtos escolhidos já possuem uma data de conclusão cadastrada."
              : "O produto escolhido já possui uma data de conclusão cadastrada.",
          bodySecundary: "Selecionar uma nova data substituirá a atual. Deseja continuar?",
          itemList: productsThatHaveDate.map((product) => ({
            labelIcon: <LqdCalendarIcon color="rgba(79, 85, 98, 1)" size={20} />,
            labelOne: "Nome do produto:",
            labelTwo: "",
            valueOne: product.name,
            valueTwo: dateFormatter(product.projectCompletionDate!).fullDateNoTimezone,
          })),
          title: "Cadastrar Nova Data",
          type: "list",
        })
      );
    } else {
      return onSubmitRegisterDate();
    }
  };

  const renderProductSelectInput = () => {
    switch (true) {
      case productsList.length > 0:
        return (
          <>
            <LqdTypography
              sx={{ borderBottom: "1px solid rgba(212, 215, 220, 1)", mt: 3, pb: 1.5 }}
              textstyle="p2Paragraph"
            >
              Selecione os Produtos em que deseja cadastrar uma data:
            </LqdTypography>
            <Box sx={{ my: 2, width: "fit-content" }}>
              <LqdSearch
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder="Buscar produtos"
                value={searchTerm}
              />
            </Box>
            <Box
              sx={{
                "::-webkit-scrollbar": { height: "8px" },
                "::-webkit-scrollbar-track": { border: "none" },
                maxHeight: "364px",
                overflowY: "auto",
                px: 1,
              }}
            >
              {filteredProducts.map((product) => (
                <BuildingDateProductCard
                  key={product.productId}
                  onChangeMethod={handleSelectProduct}
                  product={product}
                  selectedProducts={selectedProducts}
                />
              ))}
            </Box>
          </>
        );
      case !productsList.length:
        return (
          <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ height: "30px", mt: 4 }} textstyle="h5Headline">
            Ainda não há nenhum produto cadastrado para essa empresa.
          </LqdTypography>
        );
      default:
        return null;
    }
  };

  const disableSubmitButton =
    !selectedProducts.length ||
    projectCompletionDate.length !== 8 ||
    !isTimeDiffAvailable(projectCompletionDate) ||
    dialogLoading;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <SuperTenantCard currentSelectedTenant={currentSelectedTenant} />
      <Box sx={{ display: "flex", flexDirection: "column", margin: "auto", width: "90%" }}>
        <>
          {renderProductSelectInput()}
          {productsList.length > 0 ? (
            <>
              <BuildingDateInput
                inputValue={projectCompletionDate}
                mask="00/00/0000"
                onChangeMethod={setProjectCompletionDate}
                placeholder="Ex: 31/08/2025"
              />
              {projectCompletionDate.length === 8 && !isTimeDiffAvailable(projectCompletionDate) ? (
                <LqdTypography sx={{ color: "rgba(246, 61, 94, 1)", height: "10px" }} textstyle="c1Caption">
                  A data escolhida é inválida. Por favor, selecione uma data futura e válida.
                </LqdTypography>
              ) : null}
            </>
          ) : null}
        </>
      </Box>
      {productsList.length > 0 ? (
        <Box sx={{ display: "flex", justifyContent: "flex-end", margin: "auto", width: "90%" }}>
          <LqdButton disabled={disableSubmitButton} onClick={handleOpenModal} sx={{ mt: 3 }}>
            Atualizar data
          </LqdButton>
        </Box>
      ) : null}
    </Box>
  );
}
