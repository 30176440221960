import { numberFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { BasicSimulation } from "../../../../../../types/Simulation/Basic/BasicSimulation";

type PDFBasicSimulatorTableRow1Props = {
  simulation: BasicSimulation;
};

const styles = StyleSheet.create({
  allotmentTableColumn: {
    fontSize: "14px",
    width: "285px",
  },
  allotmentTableRow1Container: {
    alignItems: "center",
    borderBottom: "1px solid rgb(224, 226, 230)",
    display: "flex",
    flexDirection: "row",
    height: "92px",
    width: "100%",
  },
  allotmentTableRowText: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    textAlign: "left",
  },
  allotmentTableRowTitle: {
    alignSelf: "flex-start",
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
    textAlign: "left",
  },
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
});

export default function PDFBasicSimulatorTableRow1(props: PDFBasicSimulatorTableRow1Props) {
  const { simulation } = props;

  return (
    <View style={styles.allotmentTableRow1Container}>
      <View style={styles.allotmentTableColumn}>
        <View style={styles.genericFlexColumnContainer}>
          <Text style={styles.allotmentTableRowTitle}>Valor do bem</Text>
          <Text style={styles.allotmentTableRowText}>
            {numberFormatter(simulation.contract_value).formattedToCurrency}
          </Text>
        </View>
      </View>
      <View style={styles.allotmentTableColumn}>
        <View style={styles.genericFlexColumnContainer}>
          <Text style={styles.allotmentTableRowTitle}>Valor de entrada</Text>
          <Text style={styles.allotmentTableRowText}>
            {numberFormatter(simulation.down_payment).formattedToCurrency}
          </Text>
        </View>
      </View>
    </View>
  );
}
