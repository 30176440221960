import { numberFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, View } from "@react-pdf/renderer";
import { SBPESimulationResult } from "../../../../../../types/Simulation/SBPE/SBPESimulationResult";
import PDFSBPEUpperSimulatorInfoCard from "./PDFSBPEUpperSimulatorInfoCard";

type PDFSBPESimulatorGrayCardMainContentProps = {
  bank: SBPESimulationResult;
};

const styles = StyleSheet.create({
  sbpeSimulatorGrayCardMainContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "10px",
    paddingRight: "50px",
    paddingVertical: "10px",
    width: "100%",
  },
});

export default function PDFSBPESimulatorGrayCardMainContent(props: PDFSBPESimulatorGrayCardMainContentProps) {
  const { bank } = props;

  return (
    <View style={styles.sbpeSimulatorGrayCardMainContainer}>
      <PDFSBPEUpperSimulatorInfoCard
        title="Limite disponível de financiamento"
        value={numberFormatter(bank.max_debt_capacity).formattedToCurrency}
      />
      {bank.total_delta_fund_entry_inc > 0 ? (
        <PDFSBPEUpperSimulatorInfoCard
          title="Aumento de entrada p/ o financ. pretendido"
          value={numberFormatter(bank.total_delta_fund_entry_inc).formattedToCurrency}
        />
      ) : null}
      {bank.income_required_increase > 0 ? (
        <PDFSBPEUpperSimulatorInfoCard
          title="Renda necessária p/ financ. pretendido"
          value={numberFormatter(bank.required_income).formattedToCurrency}
        />
      ) : null}
    </View>
  );
}
