import { capitalizeString } from "@common/utils/capitalize";
import { numberFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { LiquidPassCNPJPartnersRaw } from "../../../../../types/AnalysisDetails/LiquidPass/LiquidPassPartnershipsRaw";
import PDFTableIconOrangeSVG from "../PDFTableIconOrangeSVG";

type PDFLiquidpassCNPJShareHoldingsTableProps = {
  currentPage: number;
  lastPage: number;
  tableData: Array<LiquidPassCNPJPartnersRaw>;
  tableLength: number;
};

const styles = StyleSheet.create({
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  genericFlexRowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  tableCount: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    paddingLeft: "24px",
  },
  tableFullLabel: {
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
    padding: "24px",
  },
  tableHeaderContainer: {
    alignItems: "center",
    backgroundColor: "rgb(250, 250, 251)",
    borderTop: "none",
    display: "flex",
    flexDirection: "row",
    height: "60px",
  },
  tableInnerContainer: {
    marginHorizontal: "auto",
    marginTop: "24px",
    width: "95%",
  },
  tableOuterContainer: {
    border: "1px solid rgb(222, 225, 229)",
    borderRadius: "20px",
    marginTop: "24px",
    paddingTop: "25px",
    width: "100%",
  },
  tableResults: {
    color: "rgb(127, 135, 152)",
    fontSize: "14px",
    paddingLeft: "8px",
    paddingTop: "5px",
  },
  tableRowContainer: {
    alignItems: "center",
    borderTop: "1px solid rgb(222, 225, 229)",
    display: "flex",
    flexDirection: "row",
    height: "72px",
  },
  tableTitle: {
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
    paddingLeft: "24px",
  },
  tableTotalTitle: {
    alignSelf: "flex-end",
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
  },
  tableTotalValue: {
    color: "rgb(255, 181, 71)",
    fontSize: "20px",
  },
  tableWithThreeColumns1: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "50%",
  },
  tableWithThreeColumns2: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "25%",
  },
  tableWithThreeColumns3: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "25%",
  },
});

export default function PDFLiquidpassCNPJShareHoldingsTable(props: PDFLiquidpassCNPJShareHoldingsTableProps) {
  const { currentPage, lastPage, tableData, tableLength } = props;

  const rangeStart = currentPage * 16 - 15;
  const rangeEnd = currentPage === lastPage ? tableLength : currentPage * 16;

  return (
    <View minPresenceAhead={40} style={styles.tableOuterContainer}>
      <View style={styles.genericFlexRowContainer}>
        <PDFTableIconOrangeSVG />
        <View style={styles.genericFlexColumnContainer}>
          <Text style={styles.tableTitle}>Sócios Ativos</Text>
          {tableLength > 0 ? (
            <View style={styles.genericFlexRowContainer}>
              <Text style={styles.tableCount}>{tableLength}</Text>
              {tableLength > 16 ? (
                <Text style={styles.tableResults}>{`(exibindo resultados ${rangeStart} a ${rangeEnd})`}</Text>
              ) : null}
            </View>
          ) : (
            <Text style={styles.tableCount}>0</Text>
          )}
        </View>
      </View>
      <View style={styles.tableInnerContainer}>
        <View style={styles.tableHeaderContainer}>
          <Text style={styles.tableWithThreeColumns1}>Sócio/Acionista</Text>
          <Text style={styles.tableWithThreeColumns2}>CPF</Text>
          <Text style={styles.tableWithThreeColumns3}>Participação</Text>
        </View>
        {tableData.map((partner, index) => {
          return (
            <View key={`${partner.Document}-${index}`} style={styles.tableRowContainer}>
              <Text style={styles.tableWithThreeColumns1}>{capitalizeString(partner.Name)}</Text>
              <Text style={styles.tableWithThreeColumns2}>
                {partner.DocumentFormatted || "Informação não encontrada"}
              </Text>
              <Text style={styles.tableWithThreeColumns3}>
                {numberFormatter(partner.Quote / 100).formattedToPercentage || "Informação não encontrada"}
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
}
