import {
  LqdCircularLoader,
  LqdClockIcon,
  LqdDeleteIcon,
  LqdDoneIcon,
  LqdLink,
  LqdLinkIcon,
  LqdServerDatabaseIcon,
  LqdTable,
  LqdTypography,
  LqdWorldIcon,
} from "@/liquid-components/src";
import { AutomationResult } from "@automations/types/AutomationResult";
import { useCopyToClipboard } from "@common/hooks/useCopyToClipboard";
import { dateFormatter } from "@common/utils/dataFormatter";
import { useErrorHandler } from "@common/utils/useErrorHandler";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { onGetAutomationResults, selectedAutomationChanged } from "../automationsSlice";
import AutomationPagination from "./AutomationPagination";

const AUTOMATION_COLOR_DICTIONARY = {
  error: "rgba(246, 61, 94, 1)",
  processing: "rgba(219, 145, 51, 1)",
  success: "rgba(52, 199, 89, 1)",
};

const AUTOMATION_RESULT_DICTIONARY = {
  error: "Erro",
  processing: "Processando",
  success: "Sucesso",
};

const AUTOMATION_RESULT_ICONS = {
  error: <LqdDeleteIcon color={AUTOMATION_COLOR_DICTIONARY.error} />,
  processing: <LqdClockIcon color={AUTOMATION_COLOR_DICTIONARY.processing} />,
  success: <LqdDoneIcon color={AUTOMATION_COLOR_DICTIONARY.success} />,
};

export default function AutomationDetailsView() {
  const dispatch = useAppDispatch();
  const copyValueToClipboard = useCopyToClipboard();
  const handleLiquidErrors = useErrorHandler();
  const navigate = useNavigate();
  const { automationId } = useParams();

  const { automationResults, automationResultsTotalPages, automations, selectedAutomation } = useAppSelector(
    (state) => state.automations
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [gettingResults, setGettingResults] = useState(true);

  const automation = automationId ? automations[automationId] : null;

  useEffect(() => {
    const notFoundAutomation = !automationId || automationId === undefined || !automation || automation === undefined;
    if (notFoundAutomation) {
      navigate("/automations");
    }
  }, [automationId]);

  // Carrega a lista de results do currentPage
  useEffect(() => {
    const fetchAutomationResults = async () => {
      if (!selectedAutomation && automation) {
        dispatch(selectedAutomationChanged(automation));
      }

      // Só exibe o loader caso nenhum result tenha sido carregado ainda
      if (selectedAutomation) {
        setGettingResults(true);
        try {
          await dispatch(onGetAutomationResults(currentPage));
        } catch (error) {
          handleLiquidErrors(error);
        } finally {
          setGettingResults(false);
        }
      }
    };

    fetchAutomationResults();
  }, [currentPage, selectedAutomation, automations]);

  const onCurrentPageChange = (newPage: number) => {
    if (newPage <= automationResultsTotalPages) {
      setCurrentPage(newPage);
    }
  };

  const onPrevPageClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const onNextPageClick = () => {
    if (currentPage < automationResultsTotalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const tableContent = automationResults.map((result) => {
    return [
      {
        icon: {
          color: AUTOMATION_COLOR_DICTIONARY[result.status],
          icon: <LqdServerDatabaseIcon color="rgba(255, 255, 255, 1)" />,
        },
        primaryValue: result.name,
        secondaryValue: dateFormatter(result.timestamp).fullDateText,
      },
      {
        primaryValue: (
          <Stack alignItems="center" direction="row" gap={1}>
            {AUTOMATION_RESULT_ICONS[result.status]}
            <LqdTypography color={AUTOMATION_COLOR_DICTIONARY[result.status]} textstyle="l1Label">
              {AUTOMATION_RESULT_DICTIONARY[result.status]}
            </LqdTypography>
          </Stack>
        ),
      },
      {
        primaryValue: (
          <Stack alignItems="center" direction="row" gap={1}>
            <LqdWorldIcon color={result.analysis_id ? "rgba(127, 135, 152, 1)" : "rgba(212, 215, 220, 1)"} />
            <LqdTypography
              color={result.analysis_id ? "rgba(127, 135, 152, 1)" : "rgba(212, 215, 220, 1)"}
              onClick={() => copyValueToClipboard(result.analysis_id, "ID da conexão")}
              sx={{ cursor: "pointer" }}
              textstyle="l1Label"
            >
              {result.analysis_id ? result.analysis_id.slice(0, 14) + "..." : "ID não disponível"}
            </LqdTypography>
          </Stack>
        ),
      },
      {
        primaryValue: (
          <LqdLink href={result.analysis_url}>
            <Stack alignItems="center" direction="row" spacing={1}>
              <LqdLinkIcon color={result.analysis_url ? "rgba(127, 135, 152, 1)" : "rgba(212, 215, 220, 1)"} />
              <LqdTypography
                color={result.analysis_url ? "rgba(127, 135, 152, 1)" : "rgba(212, 215, 220, 1)"}
                textstyle="l1Label"
              >
                {result.analysis_url
                  ? result.analysis_url?.split("https://")[1]?.slice(0, 40) + "..."
                  : "Link não disponível"}
              </LqdTypography>
            </Stack>
          </LqdLink>
        ),
      },
    ];
  });

  const renderContent = () =>
    automationResults.length ? (
      <Stack sx={{ height: "100%", maxWidth: "1135px", px: 4 }}>
        <Stack sx={{ width: "87.5%" }}>
          <LqdTable<AutomationResult>
            tableContent={tableContent}
            tableHeader={["", "Status", "ID da conexão", "Link da conexão"]}
          />
        </Stack>
        <Stack alignItems="flex-end" sx={{ pb: "100px", pt: 10 }}>
          <AutomationPagination
            currentPage={currentPage}
            onCurrentPageChange={onCurrentPageChange}
            onNextPageClick={onNextPageClick}
            onPrevPageClick={onPrevPageClick}
            totalPages={automationResultsTotalPages}
          />
        </Stack>
      </Stack>
    ) : (
      <LqdTypography color="rgba(127, 135, 152, 1)" textAlign="center" textstyle="h4Headline">
        Não existem conexões criadas com esta automação.
      </LqdTypography>
    );

  return gettingResults ? <LqdCircularLoader /> : renderContent();
}
