import { dateFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { ObjectOf } from "../../../../../common/types/ObjectOf";
import { User } from "../../../../../users/types/User";
import { AnalysisDetails } from "../../../../types/AnalysisDetails/Analysis/AnalysisDetails";
import { AnalysisCommentObservation } from "../../../../types/AnalysisDetails/Analysis/AnalysisDetailsComments";
import PDFSummaryApproveSVG from "./PDFSummaryApproveSVG";
import PDFSummaryReproveSVG from "./PDFSummaryReproveSVG";
import PDFTrustpadAnalysisComments from "./Trustpad/PDFTrustpadAnalysisComments";

type PDFAnalysisSummaryCardProps = {
  analysisDetails: AnalysisDetails;
  users: ObjectOf<User>;
};

const styles = StyleSheet.create({
  genericFlexRowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  mainTextHuman: {
    color: "rgb(127, 135, 152)",
    fontSize: "14px",
    paddingBottom: "12px",
  },
  majorLabelApprove: {
    color: "rgb(41, 176, 160)",
    fontSize: "24px",
    paddingBottom: "8px",
  },
  majorLabelReprove: {
    color: "rgb(246, 61, 94)",
    fontSize: "24px",
    paddingBottom: "8px",
  },
  specialText: {
    color: "rgb(33, 36, 42)",
    fontSize: "14px",
  },
  summaryContainer: {
    backgroundColor: "rgb(255, 255, 255)",
    border: "1px solid rgb(224, 226, 230)",
    borderRadius: "20px",
    marginBottom: "20px",
    maxWidth: "88%",
    paddingHorizontal: "32px",
    paddingVertical: "24px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "-20px",
  },
});

export default function PDFAnalysisSummaryCard(props: PDFAnalysisSummaryCardProps) {
  const { analysisDetails, users } = props;

  const finishedBy = <Text style={styles.specialText}>{users[analysisDetails.finishedBy as string]?.name}</Text>;
  const autoFinishedBy = <Text style={styles.specialText}>Resultado automático de acordo com a política</Text>;

  const analysisComments = analysisDetails.result.comments.observations;

  const interactedAt = () => {
    switch (true) {
      case !!analysisDetails.finishedAt:
        return <Text>{dateFormatter(analysisDetails.finishedAt).fullDateTextTime}</Text>;
      case !!analysisDetails.humanInteractionAt:
        return <Text>{dateFormatter(analysisDetails.humanInteractionAt).fullDateTextTime}</Text>;

      default:
        return null;
    }
  };

  const renderAnalysisComments = () => {
    if (!analysisComments) return <PDFTrustpadAnalysisComments comment={null} />;

    return analysisComments.map((comment: AnalysisCommentObservation) => (
      <PDFTrustpadAnalysisComments comment={comment} key={comment.comment} />
    ));
  };

  return (
    <View style={styles.summaryContainer}>
      <View style={styles.genericFlexRowContainer}>
        {analysisDetails.result.finalResult === "match" ? (
          <>
            <PDFSummaryApproveSVG />
            <View style={styles.textContainer}>
              <Text style={styles.majorLabelApprove}>Aprovado</Text>
              <Text style={styles.mainTextHuman}>
                Quem aprovou: {analysisDetails.humanInteraction ? finishedBy : autoFinishedBy}
              </Text>
              <Text style={styles.mainTextHuman}>Quando: {interactedAt()}</Text>
              {renderAnalysisComments()}
            </View>
          </>
        ) : (
          <>
            <PDFSummaryReproveSVG />
            <View style={styles.textContainer}>
              <Text style={styles.majorLabelReprove}>Reprovado</Text>
              <Text style={styles.mainTextHuman}>
                Quem reprovou: {analysisDetails.humanInteraction ? finishedBy : autoFinishedBy}
              </Text>
              <Text style={styles.mainTextHuman}>Quando: {interactedAt()}</Text>
              {renderAnalysisComments()}
            </View>
          </>
        )}
      </View>
    </View>
  );
}
