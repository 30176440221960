import { formatTrustpadDashboardCardValue } from "@analysis/utils/formatTrustpadDashboardCardValue";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { BuilderSimulation } from "../../../../../../types/Simulation/Builder/BuilderSimulation";
import PDFSBPEAndBuilderSimulatorInfoCard from "../PDFSBPEAndBuilderSimulatorInfoCard";

type PDFBuilderSimulatorHighlightsProps = {
  simulation: BuilderSimulation;
};

const styles = StyleSheet.create({
  builderSimulatorCardsSectionTitle: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingBottom: "16px",
    paddingTop: "24px",
  },
  builderSimulatorCardsSectionTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  generalInfoCardContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

export default function PDFBuilderSimulatorHighlights(props: PDFBuilderSimulatorHighlightsProps) {
  const { simulation } = props;

  const highlight = simulation.dashboard?.highlight || [];

  return (
    <View minPresenceAhead={40}>
      <View style={styles.builderSimulatorCardsSectionTitleContainer}>
        <Text style={styles.builderSimulatorCardsSectionTitle}>Destaques</Text>
      </View>
      <View minPresenceAhead={40} style={styles.generalInfoCardContainer}>
        {highlight.map((card) => (
          <PDFSBPEAndBuilderSimulatorInfoCard
            key={card.name}
            size="full-row"
            title={card.label}
            value={formatTrustpadDashboardCardValue(card.value, card.type, card.label)}
          />
        ))}
      </View>
    </View>
  );
}
