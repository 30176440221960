import { dateFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { LiquidPassProviderData } from "../../../../../types/AnalysisDetails/LiquidPass/LiquidPassProviderData";
import PDFGeneralInfoCard from "../PDFGeneralinfoCard";

type PDFLiquidpassGeneralInfoContainerProps = {
  provider: LiquidPassProviderData;
};

const styles = StyleSheet.create({
  generalInfoCardContainer: {
    display: "flex",
    flexDirection: "row",
  },
  generalInfoText: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingBottom: "40px",
    paddingTop: "40px",
  },
});

export default function PDFLiquidpassGeneralInfoContainer(props: PDFLiquidpassGeneralInfoContainerProps) {
  const { provider } = props;

  // const maritalStatusDictionary = () => {
  //   switch (provider.person?.marital_status) {
  //     case 0:
  //       return "Solteiro(a)";
  //     case 1:
  //       return "Casado(a)";
  //     case 2:
  //       return "Divorciado(a)";
  //     case 3:
  //       return "Viúvo(a)";
  //     case 99:
  //     default:
  //       return "Informação não encontrada";
  //   }
  // };

  return (
    <View minPresenceAhead={40}>
      <Text style={styles.generalInfoText}>Informações Gerais</Text>
      <View style={styles.generalInfoCardContainer}>
        {/* <PDFGeneralInfoCard
          title="Estado Civil"
          value={provider.person?.marital_status !== null ? maritalStatusDictionary() : "Informação não encontrada"}
        /> */}
        <PDFGeneralInfoCard
          title="Data de Nascimento"
          value={
            provider?.person?.birthdate
              ? dateFormatter(provider?.person?.birthdate).slashFormattedDate
              : "Informação não encontrada"
          }
        />
        {/* <PDFGeneralInfoCard title="Possui Veículos?" value={provider?.has_vehicle ? "Sim" : "Não"} /> */}
      </View>
    </View>
  );
}
