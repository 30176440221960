import { LqdIconButton, LqdTooltip, LqdTrashIcon, LqdTypography } from "@/liquid-components/src";
import { formatTrustpadDashboardCardValue } from "@analysis/utils/formatTrustpadDashboardCardValue";
import { truncateString } from "@common/utils/truncateString";
import { Box, Stack, SxProps } from "@mui/material";
import { SimulatorVariable } from "../../../../types/SimulatorVariable";

type SimulatorResultFilledCard = {
  handleDeleteClick: (index: number) => void;
  index: number;
  selectedVariable: { content: SimulatorVariable | null; id: number };
  wrapper: SxProps;
};

export default function SimulatorResultFilledCard(props: SimulatorResultFilledCard) {
  const { handleDeleteClick, index, selectedVariable, wrapper } = props;

  return (
    <Stack direction="column" spacing="auto" sx={wrapper}>
      <Box sx={{ alignSelf: "flex-end", display: "inline-flex" }}>
        <LqdIconButton onClick={() => handleDeleteClick(index)} type="ghostIcon">
          <LqdTrashIcon color="rgba(127, 135, 152, 1)" size={20} />
        </LqdIconButton>
      </Box>
      <Box>
        <Stack direction="column" gap={0.5} sx={{ width: "fit-content" }}>
          <LqdTooltip
            hidetooltip={selectedVariable!.content!.label.length > 20 ? "false" : "true"}
            placement="right"
            titlebody={selectedVariable!.content!.label}
          >
            <LqdTypography
              color="rgba(75, 75, 75, 1)"
              sx={{ overflowWrap: "break-word", width: "fit-content" }}
              textstyle="c1Caption"
            >
              {truncateString(selectedVariable!.content!.label, 20)}
            </LqdTypography>
          </LqdTooltip>
          <LqdTooltip
            hidetooltip={selectedVariable!.content!.name.length > 20 ? "false" : "true"}
            placement="right"
            titlebody={selectedVariable!.content!.name}
          >
            <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ overflowWrap: "break-word" }} textstyle="c1Caption">
              {truncateString(selectedVariable!.content!.name, 20)}
            </LqdTypography>
          </LqdTooltip>
        </Stack>
        <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p1Paragraph">
          {selectedVariable.content?.value
            ? formatTrustpadDashboardCardValue(
                selectedVariable.content.value,
                selectedVariable.content.type!,
                selectedVariable.content.label
              )
            : "Valor não definido (dinâmico)"}
        </LqdTypography>
      </Box>
    </Stack>
  );
}
