import { dateFormatter } from "@common/utils/dataFormatter";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { archiveAnalysis } from "../analysisDetails/api/ArchiveAnalysis";
import { ObjectOf } from "../common/types/ObjectOf";
import { CurrentUser } from "../users/types/CurrentUser";
import { UsersState } from "../users/usersSlice";
import { getAnalysis, GetAnalysisParams } from "./api/GetAnalysis";
import { getTotalAnalysis } from "./api/GetTotalAnalysis";
import { Analysis } from "./types/Analysis";
import { AnalysisColumn } from "./types/AnalysisColumn";
import { ANALYSIS_LOAD_AMOUNT, buildGetAnalysisParams } from "./utils/buildGetAnalysisParams";
import { formatRawAnalysis } from "./utils/formatRawAnalysis";

export type ColumnData = {
  analysisList: ObjectOf<Analysis>; // Lista de conexões desta coluna
  firstLoadAnalysisList: boolean; // Indica se a lista de conexões está sendo carregada pela primeira vez
  loadingAnalysisList: boolean; // Indica se a lista de conexões está sendo carregada
  loadingTotalAnalysisByMonth: boolean; // Indica se o total de conexões por mês está sendo carregado
  totalAnalysisByMonth: ObjectOf<number>; // Lista de meses (total de conexões do mês em questão)
  totalPages: number; // Total de páginas na paginação da coluna, dado que vem em cada request
};

export type BoardFilter = {
  archived: boolean;
  endDate: string;
  humanDecision: boolean;
  match: boolean;
  startDate: string;
  unlabelled: boolean;
  unmatch: boolean;
};

interface BoardState {
  columns: {
    [AnalysisColumn.COMPLETED]: ColumnData;
    [AnalysisColumn.MODERATION]: ColumnData;
    [AnalysisColumn.ONBOARDING]: ColumnData;
  };
  filter: BoardFilter; // Filtro do kanban
  loadingAnalysisColumn: string; // Variável que controla o loading durante o carregamento de conexões no scroll
  searchQuery: string;
  selectedAnalysisToArchive: ObjectOf<true> | null; // Lista de conexões para (des)arquivar
}

const initialState: BoardState = {
  columns: {
    [AnalysisColumn.ONBOARDING]: {
      analysisList: {} as ObjectOf<Analysis>,
      firstLoadAnalysisList: true,
      loadingAnalysisList: true,
      loadingTotalAnalysisByMonth: true,
      totalAnalysisByMonth: {},
      totalPages: 1,
    },
    [AnalysisColumn.MODERATION]: {
      analysisList: {} as ObjectOf<Analysis>,
      firstLoadAnalysisList: true,
      loadingAnalysisList: true,
      loadingTotalAnalysisByMonth: true,
      totalAnalysisByMonth: {},
      totalPages: 1,
    },
    [AnalysisColumn.COMPLETED]: {
      analysisList: {} as ObjectOf<Analysis>,
      firstLoadAnalysisList: true,
      loadingAnalysisList: true,
      loadingTotalAnalysisByMonth: true,
      totalAnalysisByMonth: {},
      totalPages: 1,
    },
  },
  filter: {
    archived: true,
    endDate: dateFormatter(new Date()).fullDate,
    humanDecision: true,
    match: true,
    startDate: "01/01/2022",
    unlabelled: true,
    unmatch: true,
  },
  loadingAnalysisColumn: "",
  searchQuery: "",
  selectedAnalysisToArchive: null,
};

/** Função que auxilia o thunk onLoadAnalysisList. */
const loadColumnAnalysis = async (
  currentAnalysisCount: number,
  totalPages: number,
  params: GetAnalysisParams
): Promise<{ newAnalysisList: Array<Analysis>; newTotalPages: number }> => {
  const nextPage = Math.ceil(currentAnalysisCount / ANALYSIS_LOAD_AMOUNT) + 1;

  if (nextPage <= totalPages) {
    // Enquanto houver a próxima página de conexões
    const getAnalysisRes = await getAnalysis(params);
    const newTotalPages = getAnalysisRes.data.total_pages || 1;
    const rawAnalysisList = getAnalysisRes.data.results;
    const newAnalysisList = rawAnalysisList.map((rawAnalysis) => formatRawAnalysis(rawAnalysis));
    return { newAnalysisList, newTotalPages };
  } else {
    return { newAnalysisList: [], newTotalPages: 1 };
  }
};

export const onLoadAnalysisList = createAsyncThunk(
  "board/onLoadAnalysisList",
  async (
    {
      column,
      currentAnalysisCount,
    }: {
      column: AnalysisColumn;
      currentAnalysisCount: number;
    },
    { getState }
  ) => {
    const { board, users } = getState() as { board: BoardState; users: UsersState };
    const { filter, searchQuery } = board;
    const { currentUser } = users;
    const totalPages = board.columns[column].totalPages;
    const params = buildGetAnalysisParams(
      column,
      filter,
      searchQuery,
      currentAnalysisCount,
      (currentUser as CurrentUser)?.userRole
    );

    const { newAnalysisList, newTotalPages } = await loadColumnAnalysis(currentAnalysisCount, totalPages, params);
    return { column, newAnalysisList, newTotalPages };
  }
);

export const onClearAllAnalysis = createAsyncThunk("board/onClearAllAnalysis", (_, { dispatch }) => {
  dispatch(totalPagesChanged({ column: AnalysisColumn.COMPLETED, newTotalPages: 1 }));
  dispatch(totalPagesChanged({ column: AnalysisColumn.MODERATION, newTotalPages: 1 }));
  dispatch(totalPagesChanged({ column: AnalysisColumn.ONBOARDING, newTotalPages: 1 }));
  dispatch(analysisColumnCleared(AnalysisColumn.COMPLETED));
  dispatch(analysisColumnCleared(AnalysisColumn.MODERATION));
  dispatch(analysisColumnCleared(AnalysisColumn.ONBOARDING));
});

export const onLoadTotalAnalysisByMonth = createAsyncThunk(
  // Carrega o total de conexões
  "board/onLoadTotalAnalysisByMonth",
  async (column: AnalysisColumn, { getState }) => {
    const state = getState() as { board: BoardState; users: UsersState };
    const { columns, filter, searchQuery } = state.board;
    const { currentUser } = state.users;

    const currentAnalysisCount = Object.values(columns[column].analysisList).length;
    const userProfile = currentUser?.userRole as string;
    const params = buildGetAnalysisParams(column, filter, searchQuery, currentAnalysisCount, userProfile);

    const getTotalAnalysisRes = await getTotalAnalysis(params);
    const totalAnalysis = getTotalAnalysisRes.data;

    return { column, totalAnalysis };
  }
);

export const onArchiveSelectedAnalysis = createAsyncThunk(
  "board/onArchiveSelectedAnalysis",
  async (analysisList: ObjectOf<boolean>) => {
    const idsList: Array<ObjectOf<boolean>> = [];
    Object.keys(analysisList).forEach((analysisId) => idsList.push({ [analysisId]: analysisList[analysisId] }));
    await archiveAnalysis(idsList);
    return analysisList;
  }
);

const boardSlice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(onLoadAnalysisList.pending, (state, action) => {
        const { column } = action.meta.arg;
        state.loadingAnalysisColumn = column;
        state.columns[column].loadingAnalysisList = true;
      })
      .addCase(onLoadAnalysisList.fulfilled, (state, action) => {
        state.loadingAnalysisColumn = "";
        const { column, newAnalysisList, newTotalPages } = action.payload;
        newAnalysisList.forEach((analysis) => {
          state.columns[column].analysisList[analysis.id] = analysis;
        });
        state.columns[column].totalPages = newTotalPages;
        state.columns[column].loadingAnalysisList = false;
        state.columns[column].firstLoadAnalysisList = false;
      })
      .addCase(onLoadTotalAnalysisByMonth.pending, (state, action) => {
        const column = action.meta.arg;
        state.columns[column].loadingTotalAnalysisByMonth = true;
      })
      .addCase(onLoadTotalAnalysisByMonth.fulfilled, (state, action) => {
        const { column, totalAnalysis } = action.payload;
        state.columns[column].totalAnalysisByMonth = totalAnalysis;
        state.columns[column].loadingTotalAnalysisByMonth = false;
      })
      .addCase(onArchiveSelectedAnalysis.fulfilled, (state, action) => {
        const analysisList = action.payload;
        Object.keys(analysisList).forEach((analysisId) => {
          const archived = analysisList[analysisId];
          if (state.columns.onboarding.analysisList[analysisId]) {
            state.columns.onboarding.analysisList[analysisId].archived = archived;
          } else if (state.columns.moderation.analysisList[analysisId]) {
            state.columns.moderation.analysisList[analysisId].archived = archived;
          } else if (state.columns.completed.analysisList[analysisId]) {
            state.columns.completed.analysisList[analysisId].archived = archived;
          }
        });
      }),
  initialState,
  name: "board",
  reducers: {
    analysisArchivedChanged(state, action: PayloadAction<{ analysisId: string; archived: boolean }>) {
      const { analysisId, archived } = action.payload;
      if (state.columns.onboarding.analysisList[analysisId]) {
        state.columns.onboarding.analysisList[analysisId].archived = archived;
      } else if (state.columns.moderation.analysisList[analysisId]) {
        state.columns.moderation.analysisList[analysisId].archived = archived;
      } else if (state.columns.completed.analysisList[analysisId]) {
        state.columns.completed.analysisList[analysisId].archived = archived;
      }
    },
    analysisArchivingToggled(state) {
      if (state.selectedAnalysisToArchive) {
        state.selectedAnalysisToArchive = null;
        state.filter.archived = true;
      } else {
        state.selectedAnalysisToArchive = {};
        state.filter.archived = false;
      }
    },
    analysisColumnCleared(state, action: PayloadAction<AnalysisColumn>) {
      const column = action.payload;
      state.columns[column].analysisList = {};
    },
    analysisSelected(state, action: PayloadAction<{ analysisId: string }>) {
      const { analysisId } = action.payload;
      const selectedAnalysisToArchive = state.selectedAnalysisToArchive || ({} as ObjectOf<true>);
      selectedAnalysisToArchive[analysisId] = true;
      state.selectedAnalysisToArchive = selectedAnalysisToArchive;
    },
    analysisUnselected(state, action: PayloadAction<{ analysisId: string }>) {
      const { analysisId } = action.payload;
      if (state.selectedAnalysisToArchive) {
        delete state.selectedAnalysisToArchive[analysisId];
      }
    },
    boardFilterDateChanged(state, action: PayloadAction<{ name: "endDate" | "startDate"; value: string }>) {
      const { name, value } = action.payload;
      state.filter[name] = value;
    },
    boardFilterToggled(
      state,
      action: PayloadAction<{ name: "archived" | "humanDecision" | "match" | "unlabelled" | "unmatch"; value: boolean }>
    ) {
      const { name, value } = action.payload;
      state.filter[name] = value;
    },
    onAnalysisAdded(state, action: PayloadAction<{ analysis: Analysis; column: AnalysisColumn }>) {
      const { analysis, column } = action.payload;

      state.columns[column].analysisList[analysis.id] = analysis;
    },
    onAnalysisDeleted(state, action: PayloadAction<string>) {
      const analysisId = action.payload;
      delete state.columns[AnalysisColumn.COMPLETED].analysisList[analysisId];
      delete state.columns[AnalysisColumn.MODERATION].analysisList[analysisId];
      delete state.columns[AnalysisColumn.ONBOARDING].analysisList[analysisId];
    },
    onAnalysisUpdateMonthCount(
      state,
      action: PayloadAction<{ analysis: Analysis; column: AnalysisColumn; previousColumn?: AnalysisColumn }>
    ) {
      const { analysis, column, previousColumn } = action.payload;

      const createdAtDate = new Date(analysis.createdAt);
      let month = (createdAtDate.getMonth() + 1).toString();
      const year = createdAtDate.getFullYear().toString();

      if (month.length === 1) month = `0${month}`;

      const formattedLabel = `${month}-${year}`;

      if (!state.columns[column].totalAnalysisByMonth[formattedLabel]) {
        state.columns[column].totalAnalysisByMonth[formattedLabel] = 1;
      } else {
        state.columns[column].totalAnalysisByMonth[formattedLabel] += 1;
      }

      if (previousColumn && state.columns[previousColumn].totalAnalysisByMonth[formattedLabel]) {
        state.columns[previousColumn].totalAnalysisByMonth[formattedLabel] -= 1;
      }
    },
    searchQueryChanged(state, action: PayloadAction<string>) {
      const searchQuery = action.payload;
      state.searchQuery = searchQuery;
    },
    totalPagesChanged(state, action: PayloadAction<{ column: AnalysisColumn; newTotalPages: number }>) {
      const { column, newTotalPages } = action.payload;
      state.columns[column].totalPages = newTotalPages;
    },
  },
});

export const {
  analysisArchivedChanged,
  analysisArchivingToggled,
  analysisColumnCleared,
  analysisSelected,
  analysisUnselected,
  boardFilterDateChanged,
  boardFilterToggled,
  onAnalysisAdded,
  onAnalysisDeleted,
  onAnalysisUpdateMonthCount,
  searchQueryChanged,
  totalPagesChanged,
} = boardSlice.actions;

export default boardSlice.reducer;
