import { LqdCalendarIcon, LqdTypography } from "@/liquid-components/src";
import { dateFormatter } from "@common/utils/dataFormatter";
import { Avatar, Box, SxProps } from "@mui/material";
import { User } from "../../users/types/User";
import { GroupResponse } from "../types/GroupResponse";
import { ProductResponse } from "../types/ProductResponse";

type SuperRowInfoProps = {
  data: GroupResponse | ProductResponse | User;
  hideDate?: boolean;
  sx?: SxProps;
};

export default function SuperRowInfo(props: SuperRowInfoProps) {
  const { data, hideDate, sx } = props;

  const title1 = (data as GroupResponse).name || (data as ProductResponse).name || (data as User).name;
  const title2 = (data as GroupResponse).code || (data as ProductResponse).segment || (data as User).email;

  return (
    <Box sx={{ alignItems: "center", display: "flex", ...sx }}>
      <Avatar
        alt="Imagem do produto."
        src={(data as ProductResponse).image || "/img/generic-product.png"}
        sx={{ height: "40px", ml: 2, mr: 1.5, width: "40px" }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "340px" }}>
        <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
          {title1}
        </LqdTypography>
        <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="p2Paragraph">
          {title2}
        </LqdTypography>
      </Box>
      {!hideDate ? (
        <Box sx={{ alignItems: "center", display: "flex" }}>
          <LqdCalendarIcon color="rgba(127, 135, 152, 1)" />
          <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ ml: 1 }} textstyle="p2Paragraph">
            {dateFormatter((data as ProductResponse).projectCompletionDate!).fullDateNoTimezone || "Não definido"}
          </LqdTypography>
        </Box>
      ) : null}
    </Box>
  );
}
