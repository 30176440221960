import { numberFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { ObjectOf } from "../../../../../../../common/types/ObjectOf";
import { SBPESimulationHighlight } from "../../../../../../types/Simulation/SBPE/SBPESimulationHighlight";
import PDFSBPESimulatorWalletSVG from "./PDFSBPESimulatorWalletSVG";

type PDFSBPESimulatorBuildingTimeTableProps = {
  highlight: SBPESimulationHighlight;
};

const styles = StyleSheet.create({
  genericFlexRowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  labelApprove: {
    backgroundColor: "rgb(212, 251, 232)",
    borderRadius: "20px",
    color: "rgb(7, 65, 84)",
    fontSize: "14px",
    paddingBottom: "7px",
    paddingLeft: "13px",
    paddingRight: "12px",
    paddingTop: "8px",
  },
  labelReprove: {
    backgroundColor: "rgb(255, 218, 209)",
    borderRadius: "20px",
    color: "rgb(118, 11, 70)",
    fontSize: "14px",
    paddingBottom: "7px",
    paddingLeft: "13px",
    paddingRight: "12px",
    paddingTop: "8px",
  },
  tableHeaderContainer: {
    alignItems: "center",
    backgroundColor: "rgb(250, 250, 251)",
    borderTop: "none",
    display: "flex",
    flexDirection: "row",
    height: "60px",
  },
  tableHeaderTitle: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    paddingLeft: "16px",
  },
  tableInnerContainer: {
    marginTop: "24px",
  },
  tableOuterContainer: {
    border: "1px solid rgb(222, 225, 229)",
    borderRadius: "20px",
    marginTop: "24px",
    padding: "24px",
    width: "100%",
  },
  tableRowContainer: {
    alignItems: "center",
    borderTop: "1px solid rgb(222, 225, 229)",
    display: "flex",
    flexDirection: "row",
    height: "72px",
  },
  tableWithThreeColumns1: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "34%",
  },
  tableWithThreeColumns2: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "33%",
  },
  tableWithThreeColumns3: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "33%",
  },
});

export default function PDFSBPESimulatorBuildingTimeTable(props: PDFSBPESimulatorBuildingTimeTableProps) {
  const { highlight } = props;

  const percentageStyles: Array<ObjectOf<string>> = [styles.tableWithThreeColumns3];

  if (highlight.monthly_income_commitment > 0.3) {
    percentageStyles.push({
      ...styles.labelReprove,
      marginLeft: "20px",
      width: "auto",
    });
  } else {
    percentageStyles.push({
      ...styles.labelApprove,
      marginLeft: "20px",
      width: "auto",
    });
  }

  return (
    <View minPresenceAhead={40} style={styles.tableOuterContainer}>
      <View style={styles.genericFlexRowContainer}>
        <PDFSBPESimulatorWalletSVG />
        <Text style={styles.tableHeaderTitle}>Pagamento durante a obra</Text>
      </View>
      <View style={styles.tableInnerContainer}>
        <View style={styles.tableHeaderContainer}>
          <Text style={styles.tableWithThreeColumns1}>Prazo</Text>
          <Text style={styles.tableWithThreeColumns2}>Parcelas mensais</Text>
          <Text style={styles.tableWithThreeColumns3}>Comprometimento de Renda</Text>
        </View>
        <View style={styles.tableRowContainer}>
          <Text style={styles.tableWithThreeColumns1}>{`${highlight.months_until_building} meses`}</Text>
          <Text style={styles.tableWithThreeColumns2}>
            {numberFormatter(highlight.monthly_building_payment).formattedToCurrency}
          </Text>
          <Text style={percentageStyles}>
            {numberFormatter(highlight.monthly_income_commitment).formattedToPercentage}
          </Text>
        </View>
      </View>
    </View>
  );
}
