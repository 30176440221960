import { LqdInfoCard, LqdInfoCardContainer } from "@/liquid-components/src";
import { formatTrustpadDashboardCardValue } from "@analysis/utils/formatTrustpadDashboardCardValue";
import { SimulatorTesterSearchData } from "@simulatorBuilder/types/simulationTester";
import { BuilderSimulation } from "../../../../../types/Simulation/Builder/BuilderSimulation";

type BuilderSimulatorInsightCardsProps = {
  inputSearchData?: SimulatorTesterSearchData;
  simulation: BuilderSimulation;
};

export default function BuilderSimulatorInsightCards(props: BuilderSimulatorInsightCardsProps) {
  const { inputSearchData, simulation } = props;

  const cards = simulation.dashboard?.cards || [];

  return (
    <LqdInfoCardContainer>
      {cards.map((card) => (
        <LqdInfoCard
          cardSubtitle={card.name}
          cardTitle={card.label}
          cardType="insight"
          cardValue={formatTrustpadDashboardCardValue(card.value, card.type, card.label)}
          inputSearchData={inputSearchData}
          key={card.name}
        />
      ))}
    </LqdInfoCardContainer>
  );
}
