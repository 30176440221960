import { numberFormatter } from "@common/utils/dataFormatter";
import { StyleSheet, View } from "@react-pdf/renderer";
import { SBPESimulationHighlight } from "../../../../../../types/Simulation/SBPE/SBPESimulationHighlight";
import PDFSBPEAndBuilderSimulatorInfoCard from "../PDFSBPEAndBuilderSimulatorInfoCard";

type PDFSBPESimulatorGeneralInfoContainerProps = {
  highlight: SBPESimulationHighlight;
};

const styles = StyleSheet.create({
  generalInfoCardContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "40px",
  },
});

export default function PDFSBPESimulatorGeneralInfoContainer(props: PDFSBPESimulatorGeneralInfoContainerProps) {
  const { highlight } = props;

  const reachedPayment =
    highlight.building_payment_over_time === 0
      ? null
      : highlight.max_time_payment >= highlight.building_payment_over_time;
  const reachedFinancing = highlight.max_financing_value >= highlight.desired_financing_value;

  return (
    <>
      <View minPresenceAhead={40} style={styles.generalInfoCardContainer}>
        <PDFSBPEAndBuilderSimulatorInfoCard
          size="large"
          title="Composição das rendas"
          value={numberFormatter(highlight.monthly_income, "R$", true).formattedToCurrency}
        />
        <PDFSBPEAndBuilderSimulatorInfoCard
          size="large"
          title="Valor da proposta"
          value={numberFormatter(highlight.contract_value, "R$", true).formattedToCurrency}
        />
      </View>
      <View minPresenceAhead={40} style={styles.generalInfoCardContainer}>
        <PDFSBPEAndBuilderSimulatorInfoCard
          size="small"
          title="Entrada (sinal)"
          value={numberFormatter(highlight.down_payment, "R$", true).formattedToCurrency}
        />
        <PDFSBPEAndBuilderSimulatorInfoCard
          iconRender={reachedPayment}
          size="small"
          title="Pagamento durante a obra desejado"
          value={
            highlight.months_until_building
              ? numberFormatter(highlight.building_payment_over_time, "R$", true).formattedToCurrency
              : "Indisponível"
          }
        />
        <PDFSBPEAndBuilderSimulatorInfoCard
          iconRender={reachedFinancing}
          size="small"
          title="Financiamento bancário desejado"
          value={numberFormatter(highlight.desired_financing_value, "R$", true).formattedToCurrency}
        />
      </View>
    </>
  );
}
